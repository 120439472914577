import React, { useState } from 'react';

function FieldButtons() {
  const [activeButton, setActiveButton] = useState('Field Health');

  const ButtonComponent = ({ text }) => {
    const isActive = activeButton === text;
    return (
      <button
        onClick={() => setActiveButton(text)}
        className={`py-1 px-2 rounded-md ${
          isActive ? 'bg-customGreen text-white' : 'bg-gray-200 text-gray-600'
        }`}
      >
        {text}
      </button>
    );
  };

  return (
    <div className="flex  bg-gray-200 p-2 rounded-md">
      <ButtonComponent text="Field Health" />
      <ButtonComponent text="Historical Weather" />
    </div>
  );
}

export default FieldButtons;
