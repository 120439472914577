import React from 'react'
import FieldButtons from './Button'
import FieldHealthChart from './FieldChart'
import FieldStatistics from './Statistics'

export default function FeildOverviwe() {
  return (
    <div className='mx-14 w-full'>
        <div className='my-4 flex justify-between items-center'>
            <p className='font-bold text-lg'>Field Overviwe</p>
            <FieldButtons/>
        </div>
      <FieldHealthChart/>
      <FieldStatistics/>
    </div>
  )
}
