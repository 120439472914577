import { useEffect } from "react";
import Plot from "react-plotly.js";

const z = [
  [
    7.0, 5.0, 6.0, 6.0, 6.0, 8.0, 10.0, 13.0, 13.0, 14.0, 17.0, 15.0, 18.0,
    25.0, 24.0, 23.0, 23.0, 21.0, 23.0, 21.0, 14.0, 12.0, 13.0, 17.0, 21.0,
    22.0, 20.0, 14.0, 12.0, 14.0, 11.0, 12.0, 15.0, 14.0, 14.0, 16.0, 18.0,
    19.0, 18.0, 18.0, 17.0, 18.0, 20.0, 20.0, 21.0, 19.0, 15.0, 13.0, 15.0,
    15.0, 14.0, 11.0, 11.0, 11.0, 12.0, 15.0, 17.0, 16.0, 13.0, 13.0, 18.0,
    24.0, 25.0, 24.0, 19.0,
  ],
  [
    7.0, 6.0, 6.0, 10.0, 9.0, 9.0, 11.0, 13.0, 14.0, 15.0, 17.0, 16.0, 20.0,
    21.0, 20.0, 19.0, 19.0, 17.0, 17.0, 15.0, 16.0, 17.0, 15.0, 17.0, 18.0,
    17.0, 15.0, 12.0, 12.0, 13.0, 10.0, 10.0, 10.0, 8.0, 8.0, 10.0, 12.0, 14.0,
    11.0, 11.0, 13.0, 13.0, 14.0, 12.0, 13.0, 15.0, 14.0, 12.0, 12.0, 11.0,
    10.0, 8.0, 9.0, 10.0, 13.0, 17.0, 19.0, 17.0, 14.0, 14.0, 18.0, 22.0, 23.0,
    23.0, 21.0,
  ],
  [
    9.0, 8.0, 8.0, 12.0, 11.0, 11.0, 9.0, 10.0, 13.0, 12.0, 11.0, 12.0, 16.0,
    16.0, 16.0, 18.0, 17.0, 18.0, 14.0, 10.0, 14.0, 18.0, 18.0, 17.0, 17.0,
    17.0, 17.0, 16.0, 15.0, 11.0, 7.0, 8.0, 9.0, 8.0, 7.0, 7.0, 7.0, 7.0, 8.0,
    9.0, 11.0, 12.0, 12.0, 10.0, 8.0, 10.0, 11.0, 10.0, 10.0, 9.0, 8.0, 8.0,
    9.0, 10.0, 12.0, 15.0, 17.0, 15.0, 14.0, 15.0, 16.0, 19.0, 21.0, 19.0, 18.0,
  ],
  [
    10.0, 10.0, 8.0, 8.0, 9.0, 10.0, 8.0, 10.0, 12.0, 10.0, 9.0, 7.0, 7.0, 8.0,
    8.0, 14.0, 12.0, 16.0, 15.0, 13.0, 17.0, 20.0, 18.0, 14.0, 15.0, 17.0, 19.0,
    19.0, 17.0, 11.0, 7.0, 8.0, 9.0, 9.0, 9.0, 9.0, 8.0, 8.0, 10.0, 10.0, 9.0,
    9.0, 11.0, 10.0, 7.0, 7.0, 8.0, 8.0, 8.0, 8.0, 8.0, 8.0, 9.0, 10.0, 11.0,
    12.0, 14.0, 13.0, 12.0, 15.0, 16.0, 19.0, 21.0, 21.0, 19.0,
  ],
  [
    8.0, 8.0, 7.0, 7.0, 8.0, 7.0, 7.0, 7.0, 7.0, 6.0, 7.0, 7.0, 7.0, 8.0, 10.0,
    15.0, 17.0, 22.0, 20.0, 18.0, 19.0, 18.0, 16.0, 12.0, 12.0, 16.0, 20.0,
    20.0, 18.0, 13.0, 11.0, 9.0, 9.0, 10.0, 10.0, 9.0, 6.0, 5.0, 8.0, 9.0, 8.0,
    7.0, 7.0, 7.0, 6.0, 7.0, 7.0, 8.0, 7.0, 7.0, 7.0, 9.0, 12.0, 13.0, 13.0,
    12.0, 12.0, 12.0, 12.0, 15.0, 16.0, 20.0, 22.0, 21.0, 17.0,
  ],
  [
    8.0, 6.0, 6.0, 6.0, 5.0, 4.0, 6.0, 6.0, 6.0, 7.0, 9.0, 9.0, 11.0, 13.0,
    14.0, 16.0, 19.0, 23.0, 21.0, 18.0, 16.0, 14.0, 12.0, 8.0, 7.0, 13.0, 18.0,
    19.0, 18.0, 17.0, 15.0, 12.0, 10.0, 10.0, 8.0, 7.0, 4.0, 3.0, 7.0, 9.0, 8.0,
    5.0, 4.0, 4.0, 5.0, 7.0, 9.0, 10.0, 9.0, 7.0, 7.0, 9.0, 13.0, 16.0, 14.0,
    12.0, 10.0, 11.0, 13.0, 14.0, 17.0, 20.0, 22.0, 21.0, 18.0,
  ],
  [
    4.0, 3.0, 4.0, 4.0, 4.0, 5.0, 7.0, 7.0, 7.0, 7.0, 7.0, 12.0, 16.0, 18.0,
    18.0, 17.0, 18.0, 19.0, 18.0, 17.0, 15.0, 12.0, 9.0, 6.0, 6.0, 11.0, 14.0,
    15.0, 15.0, 17.0, 17.0, 13.0, 9.0, 8.0, 6.0, 5.0, 5.0, 6.0, 8.0, 10.0, 9.0,
    6.0, 4.0, 3.0, 3.0, 7.0, 11.0, 12.0, 11.0, 8.0, 7.0, 9.0, 10.0, 10.0, 11.0,
    10.0, 9.0, 10.0, 11.0, 12.0, 14.0, 16.0, 17.0, 16.0, 14.0,
  ],
  [
    2.0, 1.0, 2.0, 2.0, 4.0, 9.0, 12.0, 9.0, 8.0, 8.0, 11.0, 18.0, 19.0, 17.0,
    19.0, 16.0, 17.0, 16.0, 13.0, 14.0, 14.0, 11.0, 9.0, 7.0, 7.0, 9.0, 10.0,
    7.0, 7.0, 11.0, 12.0, 9.0, 5.0, 4.0, 4.0, 4.0, 5.0, 7.0, 10.0, 10.0, 8.0,
    7.0, 5.0, 4.0, 3.0, 4.0, 8.0, 10.0, 9.0, 9.0, 7.0, 6.0, 5.0, 5.0, 7.0, 6.0,
    6.0, 8.0, 9.0, 10.0, 10.0, 11.0, 10.0, 10.0, 9.0,
  ],
  [
    4.0, 5.0, 4.0, 3.0, 5.0, 8.0, 10.0, 9.0, 7.0, 8.0, 9.0, 9.0, 10.0, 10.0,
    10.0, 13.0, 13.0, 9.0, 7.0, 8.0, 8.0, 8.0, 7.0, 4.0, 3.0, 4.0, 5.0, 4.0,
    1.0, 3.0, 5.0, 5.0, 5.0, 5.0, 7.0, 9.0, 6.0, 5.0, 11.0, 8.0, 6.0, 6.0, 4.0,
    4.0, 4.0, 4.0, 7.0, 8.0, 8.0, 7.0, 8.0, 8.0, 6.0, 4.0, 5.0, 6.0, 6.0, 6.0,
    7.0, 6.0, 6.0, 5.0, 5.0, 5.0, 5.0,
  ],
  [
    8.0, 7.0, 8.0, 9.0, 9.0, 6.0, 6.0, 6.0, 8.0, 11.0, 8.0, 4.0, 4.0, 7.0, 7.0,
    8.0, 4.0, 4.0, 4.0, 4.0, 3.0, 4.0, 5.0, 4.0, 3.0, 3.0, 3.0, 4.0, 1.0, 1.0,
    2.0, 4.0, 8.0, 7.0, 6.0, 6.0, 4.0, 2.0, 3.0, 2.0, 3.0, 3.0, 3.0, 1.0, 2.0,
    4.0, 8.0, 8.0, 6.0, 5.0, 7.0, 11.0, 8.0, 4.0, 5.0, 7.0, 7.0, 5.0, 3.0, 3.0,
    3.0, 3.0, 3.0, 3.0, 3.0,
  ],
  [
    13.0, 7.0, 11.0, 7.0, 6.0, 5.0, 5.0, 5.0, 7.0, 10.0, 8.0, 5.0, 5.0, 5.0,
    4.0, 5.0, 4.0, 2.0, 3.0, 3.0, 1.0, 3.0, 4.0, 3.0, 3.0, 3.0, 7.0, 7.0, 2.0,
    1.0, 2.0, 1.0, 2.0, 2.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0,
    3.0, 6.0, 6.0, 6.0, 5.0, 6.0, 5.0, 8.0, 7.0, 5.0, 8.0, 9.0, 9.0, 6.0, 2.0,
    3.0, 6.0, 9.0, 11.0, 12.0, 11.0,
  ],
  [
    15.0, 8.0, 9.0, 9.0, 5.0, 4.0, 5.0, 5.0, 7.0, 8.0, 6.0, 6.0, 8.0, 7.0, 4.0,
    5.0, 4.0, 1.0, 4.0, 7.0, 3.0, 2.0, 2.0, 2.0, 2.0, 2.0, 4.0, 4.0, 6.0, 6.0,
    4.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 5.0,
    8.0, 9.0, 10.0, 11.0, 11.0, 11.0, 11.0, 11.0, 12.0, 11.0, 10.0, 9.0, 5.0,
    2.0, 3.0, 4.0, 5.0, 1.0, 0.0, 0.0,
  ],
  [
    10.0, 5.0, 9.0, 9.0, 7.0, 6.0, 5.0, 7.0, 7.0, 8.0, 7.0, 4.0, 3.0, 3.0, 4.0,
    6.0, 2.0, 1.0, 4.0, 6.0, 4.0, 2.0, 3.0, 3.0, 4.0, 4.0, 4.0, 4.0, 4.0, 1.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 4.0,
    7.0, 9.0, 10.0, 9.0, 7.0, 5.0, 5.0, 4.0, 5.0, 3.0, 3.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    16.0, 12.0, 13.0, 10.0, 6.0, 5.0, 4.0, 6.0, 6.0, 6.0, 5.0, 3.0, 2.0, 3.0,
    4.0, 9.0, 4.0, 2.0, 3.0, 2.0, 2.0, 2.0, 3.0, 2.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    22.0, 19.0, 13.0, 8.0, 4.0, 2.0, 2.0, 4.0, 3.0, 3.0, 2.0, 1.0, 1.0, 2.0,
    4.0, 6.0, 2.0, 2.0, 2.0, 2.0, 2.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    21.0, 19.0, 11.0, 8.0, 5.0, 1.0, 1.0, 3.0, 3.0, 1.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    16.0, 12.0, 10.0, 12.0, 7.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    6.0, 6.0, 9.0, 8.0, 4.0, 2.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    3.0, 3.0, 5.0, 6.0, 3.0, 2.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    2.0, 3.0, 6.0, 5.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    3.0, 3.0, 3.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    4.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    3.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    6.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  [
    13.0, 5.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
    1.0, 1.0, 1.0, 1.0, 1.0,
  ],
  [
    16.0, 12.0, 3.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 3.0, 3.0, 4.0, 3.0, 3.0, 3.0, 4.0,
    4.0, 3.0, 2.0, 2.0, 3.0,
  ],
  [
    9.0, 6.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 3.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 3.0, 3.0,
    3.0, 4.0, 4.0, 3.0, 4.0,
  ],
  [
    3.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 2.0, 1.0, 2.0, 2.0, 2.0, 2.0, 1.0,
    1.0, 1.0, 0.0, 0.0, 0.0, 0.0, 3.0, 3.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0,
    3.0, 4.0, 4.0, 4.0, 6.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 2.0, 2.0, 2.0,
    2.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 6.0, 3.0, 3.0, 6.0, 7.0, 11.0, 10.0,
    4.0, 2.0, 1.0, 0.0, 0.0, 0.0, 1.0, 3.0, 6.0, 5.0, 5.0, 5.0, 5.0, 5.0, 4.0,
    4.0, 4.0, 4.0, 5.0, 7.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 0.0, 0.0, 1.0, 2.0, 2.0, 3.0,
    3.0, 3.0, 3.0, 4.0, 3.0, 1.0, 2.0, 3.0, 4.0, 2.0, 4.0, 10.0, 10.0, 16.0,
    20.0, 12.0, 8.0, 4.0, 0.0, 0.0, 0.0, 0.0, 1.0, 10.0, 11.0, 12.0, 10.0, 6.0,
    6.0, 6.0, 6.0, 6.0, 6.0, 6.0, 7.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 3.0, 3.0, 1.0, 1.0, 0.0, 1.0, 2.0, 4.0,
    3.0, 5.0, 8.0, 12.0, 9.0, 5.0, 5.0, 8.0, 9.0, 8.0, 9.0, 18.0, 18.0, 21.0,
    22.0, 12.0, 8.0, 7.0, 3.0, 3.0, 5.0, 6.0, 8.0, 10.0, 10.0, 12.0, 10.0, 8.0,
    8.0, 8.0, 9.0, 9.0, 8.0, 8.0, 8.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 2.0, 3.0, 2.0, 3.0, 4.0, 4.0, 5.0, 6.0, 4.0, 4.0, 4.0,
    4.0, 7.0, 12.0, 17.0, 16.0, 8.0, 13.0, 23.0, 26.0, 23.0, 23.0, 28.0, 28.0,
    26.0, 19.0, 14.0, 11.0, 10.0, 8.0, 7.0, 9.0, 17.0, 14.0, 12.0, 14.0, 15.0,
    12.0, 12.0, 12.0, 13.0, 13.0, 12.0, 9.0, 8.0, 9.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 2.0, 2.0, 3.0, 3.0, 2.0, 4.0, 6.0, 10.0, 16.0, 16.0, 10.0,
    3.0, 4.0, 6.0, 8.0, 11.0, 14.0, 13.0, 19.0, 31.0, 36.0, 34.0, 32.0, 33.0,
    33.0, 29.0, 23.0, 22.0, 17.0, 12.0, 11.0, 11.0, 8.0, 16.0, 18.0, 17.0, 20.0,
    18.0, 16.0, 16.0, 16.0, 17.0, 16.0, 15.0, 14.0, 11.0, 12.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 1.0, 4.0, 5.0, 4.0, 3.0, 3.0, 3.0, 3.0, 5.0, 9.0, 17.0, 22.0, 17.0,
    9.0, 10.0, 13.0, 12.0, 13.0, 16.0, 16.0, 20.0, 25.0, 25.0, 28.0, 35.0, 40.0,
    39.0, 35.0, 29.0, 21.0, 11.0, 6.0, 9.0, 12.0, 19.0, 20.0, 19.0, 23.0, 22.0,
    16.0, 12.0, 10.0, 13.0, 16.0, 15.0, 12.0, 12.0, 12.0, 12.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    1.0, 5.0, 5.0, 4.0, 5.0, 4.0, 5.0, 6.0, 6.0, 6.0, 8.0, 10.0, 13.0, 12.0,
    13.0, 16.0, 19.0, 16.0, 13.0, 12.0, 12.0, 14.0, 16.0, 18.0, 23.0, 30.0,
    37.0, 39.0, 35.0, 28.0, 19.0, 16.0, 18.0, 17.0, 15.0, 17.0, 16.0, 15.0,
    21.0, 23.0, 18.0, 12.0, 14.0, 19.0, 20.0, 16.0, 13.0, 13.0, 16.0, 13.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
    6.0, 6.0, 5.0, 4.0, 4.0, 2.0, 4.0, 8.0, 10.0, 11.0, 11.0, 12.0, 12.0, 10.0,
    14.0, 16.0, 17.0, 17.0, 12.0, 7.0, 7.0, 11.0, 12.0, 17.0, 25.0, 26.0, 28.0,
    27.0, 24.0, 17.0, 13.0, 17.0, 23.0, 22.0, 16.0, 10.0, 8.0, 11.0, 15.0, 16.0,
    17.0, 14.0, 18.0, 20.0, 19.0, 19.0, 15.0, 14.0, 15.0, 16.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 5.0,
    7.0, 7.0, 4.0, 3.0, 2.0, 2.0, 5.0, 10.0, 14.0, 16.0, 16.0, 14.0, 13.0, 11.0,
    9.0, 11.0, 15.0, 20.0, 18.0, 11.0, 8.0, 12.0, 12.0, 15.0, 22.0, 20.0, 20.0,
    16.0, 14.0, 10.0, 11.0, 13.0, 18.0, 21.0, 15.0, 9.0, 7.0, 9.0, 10.0, 11.0,
    12.0, 15.0, 18.0, 19.0, 17.0, 18.0, 18.0, 21.0, 21.0, 18.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 4.0, 6.0,
    7.0, 6.0, 3.0, 3.0, 2.0, 4.0, 11.0, 16.0, 17.0, 17.0, 16.0, 15.0, 13.0,
    11.0, 11.0, 12.0, 13.0, 18.0, 20.0, 19.0, 14.0, 14.0, 14.0, 14.0, 16.0,
    13.0, 18.0, 15.0, 12.0, 11.0, 16.0, 20.0, 22.0, 20.0, 13.0, 10.0, 9.0, 11.0,
    11.0, 11.0, 12.0, 17.0, 17.0, 18.0, 16.0, 17.0, 20.0, 23.0, 21.0, 16.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.0, 4.0, 4.0,
    5.0, 4.0, 3.0, 3.0, 4.0, 8.0, 14.0, 17.0, 16.0, 14.0, 14.0, 14.0, 15.0,
    17.0, 17.0, 16.0, 14.0, 14.0, 16.0, 19.0, 21.0, 17.0, 15.0, 14.0, 13.0,
    12.0, 19.0, 16.0, 13.0, 15.0, 20.0, 27.0, 29.0, 25.0, 19.0, 16.0, 15.0,
    14.0, 14.0, 15.0, 15.0, 16.0, 16.0, 15.0, 15.0, 14.0, 15.0, 14.0, 14.0,
    12.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 1.0,
    1.0, 2.0, 2.0, 3.0, 6.0, 12.0, 16.0, 18.0, 16.0, 15.0, 16.0, 17.0, 19.0,
    24.0, 23.0, 24.0, 20.0, 18.0, 16.0, 16.0, 19.0, 20.0, 16.0, 14.0, 13.0,
    14.0, 18.0, 17.0, 15.0, 16.0, 21.0, 27.0, 29.0, 27.0, 24.0, 21.0, 20.0,
    18.0, 16.0, 19.0, 20.0, 19.0, 16.0, 13.0, 12.0, 11.0, 12.0, 11.0, 11.0,
    12.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0,
    0.0, 1.0, 3.0, 5.0, 9.0, 15.0, 19.0, 20.0, 22.0, 24.0, 23.0, 24.0, 25.0,
    25.0, 27.0, 27.0, 24.0, 22.0, 18.0, 15.0, 15.0, 18.0, 20.0, 19.0, 16.0,
    16.0, 17.0, 18.0, 17.0, 14.0, 17.0, 22.0, 24.0, 25.0, 22.0, 21.0, 20.0,
    19.0, 17.0, 19.0, 21.0, 22.0, 19.0, 15.0, 13.0, 13.0, 12.0, 10.0, 9.0, 10.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 2.0, 3.0, 7.0, 10.0, 18.0, 24.0, 28.0, 28.0, 30.0, 28.0, 26.0, 23.0,
    19.0, 20.0, 23.0, 25.0, 24.0, 20.0, 19.0, 19.0, 20.0, 23.0, 24.0, 23.0,
    21.0, 17.0, 13.0, 12.0, 8.0, 10.0, 17.0, 21.0, 24.0, 23.0, 21.0, 19.0, 18.0,
    17.0, 21.0, 21.0, 22.0, 21.0, 18.0, 16.0, 14.0, 10.0, 10.0, 13.0, 14.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    1.0, 2.0, 4.0, 10.0, 17.0, 24.0, 31.0, 33.0, 32.0, 31.0, 27.0, 25.0, 24.0,
    24.0, 24.0, 26.0, 27.0, 24.0, 22.0, 25.0, 25.0, 22.0, 25.0, 30.0, 27.0,
    23.0, 19.0, 12.0, 11.0, 11.0, 15.0, 19.0, 21.0, 23.0, 21.0, 19.0, 20.0,
    19.0, 17.0, 18.0, 20.0, 20.0, 20.0, 22.0, 17.0, 12.0, 8.0, 8.0, 11.0, 16.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 3.0, 3.0, 3.0, 2.0, 0.0, 0.0, 0.0, 0.0,
    2.0, 2.0, 6.0, 15.0, 26.0, 31.0, 33.0, 32.0, 32.0, 31.0, 29.0, 29.0, 28.0,
    28.0, 30.0, 31.0, 29.0, 24.0, 20.0, 24.0, 26.0, 22.0, 26.0, 30.0, 25.0,
    21.0, 21.0, 20.0, 19.0, 19.0, 18.0, 14.0, 16.0, 20.0, 21.0, 21.0, 18.0,
    18.0, 19.0, 22.0, 20.0, 20.0, 23.0, 27.0, 18.0, 11.0, 9.0, 10.0, 11.0, 15.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 4.0, 4.0, 3.0, 3.0, 4.0, 4.0, 2.0, 2.0, 3.0,
    3.0, 4.0, 11.0, 20.0, 29.0, 32.0, 31.0, 30.0, 31.0, 30.0, 27.0, 28.0, 29.0,
    28.0, 26.0, 31.0, 30.0, 25.0, 23.0, 24.0, 27.0, 32.0, 35.0, 33.0, 23.0,
    23.0, 23.0, 21.0, 22.0, 20.0, 15.0, 10.0, 14.0, 20.0, 21.0, 20.0, 20.0,
    18.0, 19.0, 16.0, 17.0, 17.0, 21.0, 22.0, 16.0, 11.0, 10.0, 9.0, 10.0, 12.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.0, 3.0, 3.0, 4.0, 4.0, 3.0, 4.0, 3.0, 3.0,
    2.0, 8.0, 20.0, 28.0, 35.0, 38.0, 34.0, 31.0, 32.0, 35.0, 35.0, 32.0, 29.0,
    29.0, 34.0, 34.0, 28.0, 24.0, 24.0, 29.0, 35.0, 37.0, 38.0, 36.0, 27.0,
    27.0, 25.0, 23.0, 24.0, 24.0, 19.0, 15.0, 19.0, 22.0, 21.0, 21.0, 22.0,
    22.0, 19.0, 17.0, 16.0, 17.0, 19.0, 19.0, 17.0, 15.0, 13.0, 13.0, 13.0,
    15.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 2.0, 3.0, 3.0, 2.0, 4.0, 3.0,
    5.0, 16.0, 27.0, 30.0, 33.0, 35.0, 34.0, 31.0, 30.0, 30.0, 32.0, 33.0, 34.0,
    31.0, 34.0, 32.0, 27.0, 25.0, 25.0, 31.0, 37.0, 39.0, 37.0, 35.0, 33.0,
    34.0, 32.0, 24.0, 21.0, 21.0, 20.0, 16.0, 18.0, 22.0, 24.0, 23.0, 23.0,
    23.0, 22.0, 19.0, 19.0, 21.0, 21.0, 19.0, 18.0, 15.0, 12.0, 12.0, 13.0,
    16.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 2.0, 4.0,
    10.0, 22.0, 34.0, 36.0, 36.0, 34.0, 32.0, 31.0, 32.0, 34.0, 33.0, 37.0,
    38.0, 35.0, 33.0, 26.0, 25.0, 25.0, 26.0, 32.0, 38.0, 38.0, 37.0, 35.0,
    33.0, 30.0, 29.0, 21.0, 22.0, 27.0, 29.0, 27.0, 24.0, 23.0, 22.0, 22.0,
    21.0, 20.0, 22.0, 22.0, 21.0, 23.0, 24.0, 23.0, 19.0, 15.0, 12.0, 11.0,
    14.0, 13.0,
  ],
  [
    0.0, 0.0, 0.0, 1.0, 2.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.0, 3.0, 8.0,
    16.0, 25.0, 34.0, 37.0, 35.0, 30.0, 29.0, 30.0, 35.0, 38.0, 35.0, 39.0,
    40.0, 35.0, 35.0, 27.0, 24.0, 25.0, 27.0, 32.0, 37.0, 35.0, 34.0, 32.0,
    31.0, 29.0, 27.0, 21.0, 24.0, 28.0, 29.0, 26.0, 22.0, 19.0, 19.0, 21.0,
    22.0, 19.0, 18.0, 20.0, 20.0, 21.0, 22.0, 23.0, 19.0, 14.0, 10.0, 8.0, 11.0,
    9.0,
  ],
  [
    0.0, 0.0, 0.0, 2.0, 2.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0, 1.0, 3.0, 7.0, 14.0,
    23.0, 29.0, 35.0, 37.0, 33.0, 30.0, 30.0, 32.0, 33.0, 36.0, 36.0, 41.0,
    40.0, 35.0, 34.0, 28.0, 26.0, 25.0, 27.0, 31.0, 32.0, 32.0, 34.0, 33.0,
    29.0, 25.0, 26.0, 22.0, 23.0, 24.0, 26.0, 24.0, 22.0, 19.0, 19.0, 21.0,
    22.0, 20.0, 18.0, 22.0, 20.0, 19.0, 18.0, 19.0, 17.0, 14.0, 10.0, 5.0, 7.0,
    7.0,
  ],
  [
    0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 1.0, 3.0, 6.0, 12.0, 20.0,
    26.0, 32.0, 34.0, 35.0, 35.0, 35.0, 35.0, 36.0, 35.0, 35.0, 34.0, 35.0,
    35.0, 32.0, 33.0, 31.0, 28.0, 26.0, 29.0, 35.0, 36.0, 34.0, 36.0, 34.0,
    28.0, 25.0, 26.0, 22.0, 20.0, 21.0, 23.0, 24.0, 23.0, 20.0, 19.0, 20.0,
    21.0, 19.0, 17.0, 19.0, 18.0, 18.0, 17.0, 18.0, 16.0, 14.0, 13.0, 9.0, 7.0,
    6.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 5.0, 9.0, 17.0, 23.0,
    28.0, 32.0, 34.0, 36.0, 38.0, 40.0, 40.0, 39.0, 39.0, 39.0, 35.0, 33.0,
    32.0, 32.0, 33.0, 30.0, 26.0, 27.0, 31.0, 36.0, 37.0, 35.0, 37.0, 37.0,
    35.0, 35.0, 32.0, 22.0, 20.0, 20.0, 21.0, 23.0, 22.0, 20.0, 19.0, 21.0,
    20.0, 17.0, 13.0, 12.0, 12.0, 13.0, 15.0, 17.0, 16.0, 12.0, 10.0, 10.0, 7.0,
    5.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.0, 3.0, 6.0, 12.0, 20.0,
    27.0, 32.0, 34.0, 37.0, 41.0, 42.0, 39.0, 39.0, 39.0, 40.0, 40.0, 37.0,
    36.0, 36.0, 38.0, 38.0, 33.0, 29.0, 28.0, 30.0, 32.0, 34.0, 33.0, 37.0,
    40.0, 42.0, 44.0, 39.0, 25.0, 21.0, 21.0, 22.0, 25.0, 26.0, 22.0, 20.0,
    23.0, 24.0, 21.0, 16.0, 11.0, 10.0, 12.0, 16.0, 15.0, 16.0, 11.0, 7.0, 8.0,
    7.0, 7.0,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 3.0, 4.0, 7.0, 13.0, 22.0,
    30.0, 35.0, 35.0, 38.0, 40.0, 40.0, 34.0, 35.0, 38.0, 38.0, 36.0, 35.0,
    36.0, 38.0, 38.0, 37.0, 36.0, 35.0, 31.0, 30.0, 29.0, 33.0, 36.0, 41.0,
    43.0, 39.0, 39.0, 33.0, 24.0, 21.0, 21.0, 22.0, 25.0, 25.0, 21.0, 20.0,
    19.0, 20.0, 19.0, 16.0, 14.0, 13.0, 15.0, 17.0, 17.0, 15.0, 10.0, 8.0, 9.0,
    10.0, 11.0,
  ],
];

const bbox = [
  [123.3214, 27.421],
  [123.1234, 26.952],
];

const round = (n) => Math.round(n * 1000000) / 1000000;

const calculatePoints = () => {
  const xJump = (bbox[1][1] - bbox[0][1]) / (z[0].length - 1);
  const yJump = (bbox[1][0] - bbox[0][0]) / (z.length - 1);

  const initialLat = bbox[0][0];
  const initialLon = bbox[0][1];

  const x = [];
  const y = [];

  let temp = [];
  for (let i = 0; i < z[0].length; i++) {
    temp.push(round(initialLon + i * xJump));
  }
  for (let i = 0; i < z.length; i++) {
    x.push(temp);
  }

  temp = [];
  for (let i = 0; i < z.length; i++) {
    temp.push(round(initialLat + i * yJump));
  }
  for (let i = 0; i < z.length; i++) {
    let temp2 = [];
    for (let j = 0; j < z[0].length; j++) {
      temp2.push(temp[i]);
    }
    y.push(temp2);
  }

  return { x, y };
};

const Plot2 = () => {
  const { x, y } = calculatePoints();
  let data = [
    {
      x,
      y,
      z,
      type: "surface",
      hovertemplate:
        "<span style='color: lime;'><b>Latitude</b>: <span style='color: white;'>%{x}</span></span><br>" +
        "<span style='color: magenta;'><b>Longitude</b>: <span style='color: white;'>%{y}</span></span><br>" +
        "<span style='color: orange;'><b>Altitude</b>: <span style='color: white;'>%{z} meters</span></span><br><extra></extra>",
    },
  ];
  //   var data = [
  //     {
  //       z: data1,
  //       type: "surface",
  //     },
  //   ];

  var layout = {
    autosize: true,
    width: 520,
    height: 350,
    margin: {
      l: 2,
      r: 2,
      b: 2,
      t: 2,
    },
    scene: {
      xaxis: {
        title: "Lon",
      },
      yaxis: {
        title: "Lat",
      },
      zaxis: {
        title: "Altitude",
      },
    },
  };

  var config = {
    displayModeBar: false,
  };
  return <Plot data={data} layout={layout} config={config} />;
};

export default Plot2;
