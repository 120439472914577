
import MyFeildCom from 'components/MyFeilds/MyFeildCom';
import React from 'react'

const MyFeilds = () => {
  return (
    <div>
        <MyFeildCom/>
    </div>
  )
}

export default MyFeilds;
