import YearDropdown from 'dashbord/YearButton';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {useState} from 'react';


const data = [
    { name: 'Jan', SoilMoisture: 1200, CropGrowth: 1320, SurfaceWater: 1180, PestControl: 1200, NitrogenPrescription: 720, BurnedLand: 2320 },
    { name: 'Feb', SoilMoisture: 1180, CropGrowth: 1140, SurfaceWater: 1910, PestControl: 1820, NitrogenPrescription: 620, BurnedLand: 2420 },
    { name: 'Mar', SoilMoisture: 1100, CropGrowth: 1500, SurfaceWater: 1420, PestControl: 1210, NitrogenPrescription: 1020, BurnedLand: 2620 },
    { name: 'Apr', SoilMoisture: 1170, CropGrowth: 1760, SurfaceWater: 110, PestControl: 920, NitrogenPrescription: 920, BurnedLand: 2520 },
    { name: 'May', SoilMoisture: 1120, CropGrowth: 1180, SurfaceWater: 1710, PestControl: 1100, NitrogenPrescription: 820, BurnedLand: 2720 },
    { name: 'Jun', SoilMoisture: 2100, CropGrowth: 1350, SurfaceWater: 1500, PestControl: 1520, NitrogenPrescription: 1210, BurnedLand: 2520 },
    { name: 'Jul', SoilMoisture: 2110, CropGrowth: 1560, SurfaceWater: 1410, PestControl: 1730, NitrogenPrescription: 1220, BurnedLand: 2620 },
    { name: 'Aug', SoilMoisture: 2150, CropGrowth: 1270, SurfaceWater: 1230, PestControl: 1150, NitrogenPrescription: 1230, BurnedLand: 1820 },
    { name: 'Sep', SoilMoisture: 1240, CropGrowth: 1480, SurfaceWater: 1910, PestControl: 1440, NitrogenPrescription: 1220, BurnedLand: 1920 },
    { name: 'Oct', SoilMoisture: 1200, CropGrowth: 1160, SurfaceWater: 110, PestControl: 1230, NitrogenPrescription: 1210, BurnedLand: 1720 },
    { name: 'Nov', SoilMoisture: 1170, CropGrowth: 1450, SurfaceWater: 910, PestControl: 1000, NitrogenPrescription: 920, BurnedLand: 1620 },
    { name: 'Dec', SoilMoisture: 1160, CropGrowth: 1740, SurfaceWater: 810, PestControl: 720, NitrogenPrescription: 820, BurnedLand: 1520 }
  ];

export default function FieldHealthChart() {
    const [selectedLines, setSelectedLines] = useState({
        SoilMoisture: true,
        CropGrowth: true,
        SurfaceWater: true,
        PestControl: true,
        NitrogenPrescription: true,
        BurnedLand: true,
      });

      const lineColors = {
        SoilMoisture: "#3C5E23",
        CropGrowth: "#FF8369",
        SurfaceWater: "#1AC8FF",
        PestControl: "#1DEB63",
        NitrogenPrescription: "#FFCD83",
        BurnedLand: "#4636FF",
      };


      const handleToggle = (line) => {
        setSelectedLines((prev) => ({
          ...prev,
          [line]: !prev[line]
        }));
      };

      return (
    <div className='border rounded-xl p-3 '>
        <div className='flex justify-between pb-3'>
            <p className='font-bold'>Feild Health</p>
            <YearDropdown/>
        </div>
     <div>
        <div className='w-[90%] m-auto'>
        <ResponsiveContainer height={250}  >
        <LineChart data={data} key={Object.values(selectedLines).join('-')}>
          <CartesianGrid vertical={false} horizontal={true} strokeOpacity={0.2}/>
          <XAxis dataKey="name" axisLine={false} dy={10} />
          <YAxis axisLine={false} dx={-15}/>
          <Tooltip/>
         
          {selectedLines.SoilMoisture && (
            <Line type="monotone" dataKey="SoilMoisture" stroke="#3C5E23" strokeWidth={2} dot={false} activeDot={{ r: 4 }}/>
          )}
          {selectedLines.CropGrowth && (
            <Line type="monotone" dataKey="CropGrowth" stroke="#FF8369" strokeWidth={2} dot={false} activeDot={{ r: 4 }}/>
          )}
          {selectedLines.SurfaceWater && (
            <Line type="monotone" dataKey="SurfaceWater" stroke="#1AC8FF" strokeWidth={2} dot={false} activeDot={{ r: 4 }} />
          )}
          {selectedLines.PestControl && (
            <Line type="monotone" dataKey="PestControl" stroke="#1DEB63" strokeWidth={2} dot={false} activeDot={{ r: 4 }} />
          )}
          {selectedLines.NitrogenPrescription && (
            <Line type="monotone" dataKey="NitrogenPrescription" stroke="#FFCD83" strokeWidth={2} dot={false} activeDot={{ r: 4 }}/>
          )}
          {selectedLines.BurnedLand && (
            <Line type="monotone" dataKey="BurnedLand" stroke="#4636FF" strokeWidth={2} dot={false} activeDot={{ r: 4 }} />
          )}
        </LineChart>
      </ResponsiveContainer>
      </div>
      <div className=" pl-14 ml-8 pt-7 grid grid-cols-3 ">
        {Object.keys(selectedLines).map((key) => (
          <div key={key} className="flex items-center mb-2"> 

<span className="ml-2 text-sm text-gray-700 flex items-center w-44">
                <div style={{ 
                  backgroundColor: lineColors[key], 
                  width: '12px', 
                  height: '12px', 
                  marginRight: '5px', 
                  borderRadius: '3px'
                }} />
                {key.replace(/([A-Z])/g, '$1')}
              </span>
              <div className=''>
            <div
                  onClick={() => handleToggle(key)}
                  className={`w-6 h-3 flex items-center rounded-full p-1 py-2 cursor-pointer ${
                    selectedLines[key] ? 'bg-customGreen' : 'bg-gray-300'
                  }`}
                >
                  <div
                    className={`bg-white w-[11px] h-[11px] rounded-full shadow-md transform transition-transform ${
                      selectedLines[key] ? 'translate-x-[8px]' : 'translate-x-[-2px]'
                    }`}
                  />
                </div>
                </div>
          </div>
        ))}
      </div>
     </div>
    </div>
  )
}