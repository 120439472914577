import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PAGES } from "constants/index";
import Header from "components/common/Header";
import Footer from "components/common/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-medium-image-zoom/dist/styles.css";
import Account from "pages/Account";
import ResetPassword from "pages/ResetPassword";
import { Toaster } from "react-hot-toast";
import LoginAuthenticator from "components/common/LoginAuthenticator";
import Plot from "pages/Plot";
import UserFields from "pages/UserFields";
import Dashboard from "pages/Dashboard";
import MyFeilds from "pages/MyFeilds";


function App() {
  return (
    <div className="App font-poppins">
      <Router>
      
        <Toaster />
        <Header />
        <Routes>
          {PAGES.map((page) => (
            <Route key={page.path} path={page.path} element={page.element} />
          ))}
          <Route path="/account" element={<Account />} />
          <Route path="/plot" element={<Plot />} />
          <Route path="/fields" element={<UserFields />} />
          <Route path="/verify-reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={ <Dashboard/> }/>
          <Route path="/MyFeilds" element={<MyFeilds/>}/>
          <Route
            path="*"
            element={
              <div className="text-3xl font-bold text-center text-red-600 py-40 bg-gray-700">
                🌍Page not found🌍
              </div>
            }
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
