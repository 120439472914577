import axios from "axios";

export const POSITION_STACK_API_KEY = process.env.REACT_APP_POSITION_STACK_KEY;

const instance = axios.create({
  baseURL: "https://api.positionstack.com/v1",
  params: {
    access_key: POSITION_STACK_API_KEY,
  },
});

export default instance;
