import React from 'react';
import YearDropdown from './YearButton'
import CustomSegmentStops from './SpeedoMeterDes'

export default function SpeedometerComponents() {
  return (
    <div className='w-[35%] h-[270px] bg-slate-50 pb-5'>
        <div className='px-1 py-1 flex justify-between items-center'>
            <p className='font-bold'>My Feild Growth</p>
           <YearDropdown/>
        </div>

        <div className=''>
         <CustomSegmentStops/>
        </div>
    </div>
  )
}

