import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Map from "components/userFields/Map";
import GeoContext from "contexts/GeoContext";
import { useCallback, useState } from "react";
import CrossIcon from "resources/common/icons/cross.svg";
import Plot2 from "./Plot";
import { calculatePolygonArea } from "utils";

const UserFields = () => {
  const [points, setPoints] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const openModal = () => setOpen(true);

  const Analytics = useCallback(() => {
    return (
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="w-full h-full pointer-events-none flex justify-center items-center">
          <div className="pointer-events-auto w-4/5 max-w-[950px] bg-white text-[#191919] rounded-2xl px-10 py-7">
            <div className="flex justify-between">
              <div className="text-gray-900 text-2xl font-semibold tracking-tight">
                Field Details
              </div>
              <img
                src={CrossIcon}
                alt=""
                className="cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="my-4 w-[540px]">
              <Divider />
            </div>
            <div className="flex justify-between gap-4 flex-wrap">
              <div className="w-[560px]">
                <div className="flex justify-between font-medium text-lg h-10 items-center">
                  <div>Selected Field</div>
                  <div className="flex items-center">
                    <div>Elevation Data</div>
                    <Switch color="success" />
                  </div>
                </div>
                <div className="border-2 overflow-hidden rounded-lg">
                  <Plot2 />
                </div>
              </div>
              <div className="w-64 mt-1">
                <div className="text-lg font-medium mb-4">Crop Name</div>
                <TextField
                  slotProps={{
                    input: {
                      className: "mb-4",
                    },
                  }}
                />
                <div className="text-lg font-medium mb-4">Crop Type</div>
                <FormControl fullWidth>
                  <Select id="demo-simple-select" value={10}>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flex justify-between items-center font-medium mt-3">
              <div>
                <div className="text-lg mb-1">Total Heacters Selected</div>
                <div className="text-2xl">{calculatePolygonArea(points)}</div>
              </div>
              <div className="flex items-center p-3 px-10 rounded-lg cursor-pointer bg-[#3C5E23] text-white">
                Add Field
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }, [open, points]);

  const props = {
    points,
    setPoints,
  };

  return (
    <GeoContext.Provider value={props}>
      <Map onViewAnalytics={openModal} />
      <Analytics />
    </GeoContext.Provider>
  );
};

export default UserFields;
