import * as turf from "@turf/turf";
import { jwtDecode } from "jwt-decode";

export const calculatePolygonArea = (points) => {
  if (points.length < 3) return 0;
  const closedPoints = [...points, points[0]];
  const polygon = turf.polygon([closedPoints]);
  const areaInSquareMeters = turf.area(polygon);
  return turf.round(areaInSquareMeters, 0);
};

export const getAverageCoord = (coords) => {
  let totalLat = 0;
  let totalLon = 0;

  coords.forEach((coord) => {
    totalLat += coord[0];
    totalLon += coord[1];
  });

  return {
    lat: totalLat / coords.length,
    lon: totalLon / coords.length,
  };
};

export const getZoomLevel = (type) => {
  switch (type) {
    case "venue":
      return 17;
    case "address":
      return 16;
    case "street":
      return 15;
    case "neighbourhood":
      return 14;
    case "borough":
      return 13;
    case "localadmin":
      return 12;
    case "locality":
      return 11;
    case "county":
      return 10;
    case "macrocounty":
      return 9;
    case "region":
      return 8;
    case "macroregion":
      return 7;
    case "country":
      return 6;
    case "coarse":
      return 5;
    case "postalcode":
      return 16;
    default:
      return 13;
  }
};

export const isUserLoggedIn = () => {
  return localStorage.token;
};

export const getUserInfo = () => {
  if (!localStorage.token) return null;
  try {
    const userInfo = jwtDecode(localStorage.token);
    const { name, email } = userInfo;
    return { name, email, subscription: "Free" };
  } catch (e) {
    setTimeout(() => {
      localStorage.removeItem("token");
      window.location.reload();
    }, 2000);
    return "ERROR";
  }
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email && emailRegex.test(email) ? null : "Invalid email address";
};

export const validate = (data, isLogin, isForgotPassword = false) => {
  const emailError = validateEmail(data.email);
  if (emailError) return emailError;
  if (isForgotPassword) return;

  if (isLogin) {
    if (!data.pass) return "Password is required";
  } else {
    if (!data.name) return "Name is required";
    if (!data.pass) return "Password is required";
    if (data.pass !== data.confirmPass) return "Passwords do not match";
  }

  return null;
};

export const findMessage = (obj, defaultMessage) => {
  let deepestMessage = defaultMessage;

  const searchDeep = (currentObj) => {
    if (currentObj !== null && typeof currentObj === "object") {
      if ("message" in currentObj) {
        deepestMessage = currentObj.message;
      }

      for (const key in currentObj) {
        if (
          currentObj.hasOwnProperty(key) &&
          typeof currentObj[key] === "object"
        ) {
          searchDeep(currentObj[key]);
        }
      }
    }
  };

  searchDeep(obj);

  return deepestMessage;
};

export const isLoggedIn = () => Boolean(localStorage.token);

export const isWebGLAvailable = () => {
  try {
    var canvas = document.createElement("canvas");
    return (
      !!window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
    );
  } catch (e) {
    return false;
  }
};

export const getImageLinks = (userId, searchId) => {
  if (!userId || !searchId) return null;
  const baseURL = process.env.REACT_APP_BASE_URL;
  const fileNames = [
    "Thousand Lakes True Color.png",
    "Thousand Lakes NDNI.png",
    "Thousand Lakes NDMI.png",
    "Thousand Lakes NBR.png",
    "Thousand Lakes mNDWI.png",
    "Thousand Lakes EVI2.png",
  ];
  return fileNames.map((file) =>
    encodeURI(`${baseURL}/user_searches/${userId}/${searchId}/${file}`)
  );
};

export const getMainImageLink = (userId, searchId) => {
  if (!userId || !searchId) return null;
  const baseURL = process.env.REACT_APP_BASE_URL;
  const fileName = "Thousand Lakes True Color.png";
  return encodeURI(
    `${baseURL}/user_searches/${userId}/${searchId}/${fileName}`
  );
};

const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

const latLonToTileCoords = (lat, lon, zoom) => {
  // Convert latitude and longitude to pixel coordinates
  const x = (lon + 180) / 360;
  const y =
    (1 -
      Math.log(
        Math.tan(degreesToRadians(lat)) + 1 / Math.cos(degreesToRadians(lat))
      ) /
        Math.PI) /
    2;

  const n = Math.pow(2, zoom);
  const pixelX = x * 256 * n;
  const pixelY = y * 256 * n;

  // Calculate tile coordinates
  const tileX = Math.floor(pixelX / 256);
  const tileY = Math.floor(pixelY / 256);

  return { tileX, tileY };
};

export const getGoogleImageryUrl = (coords, zoom = 13) => {
  const { lat, lon } = getAverageCoord(coords);
  const { tileX, tileY } = latLonToTileCoords(lat, lon, zoom);
  return `https://mt1.google.com/vt/lyrs=y&x=${tileX}&y=${tileY}&z=${zoom}`;
};

export const getMidPoints = (coords) => {
  if (coords.length < 3) {
    return [];
  }

  const midPoints = [];

  // Calculate midpoints between consecutive points
  for (let i = 0; i < coords.length - 1; i++) {
    const [lat1, lon1] = coords[i];
    const [lat2, lon2] = coords[i + 1];

    const midLat = (lat1 + lat2) / 2;
    const midLon = (lon1 + lon2) / 2;

    midPoints.push([midLat, midLon]);
  }

  if (coords.length > 2) {
    const [firstLat, firstLon] = coords[0];
    const [lastLat, lastLon] = coords[coords.length - 1];

    const midLat = (firstLat + lastLat) / 2;
    const midLon = (firstLon + lastLon) / 2;

    midPoints.push([midLat, midLon]);
  }

  return midPoints;
};
