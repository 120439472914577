import React, { useState } from 'react';

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState(null);

  const menuItems = [
    { name: 'Dashboard', id: 'dashboard' },
    { name: 'My Fields', id: 'myFields' },
    { name: 'Search', id: 'search' },
    { name: 'Reports', id: 'reports' },
    { name: 'Integration', id: 'integration' },
    { name: 'My Profile', id: 'myProfile' },
    { name: 'Summaries', id: 'summaries' },
    { name: 'Anomaly Detection', id: 'anomalyDetection' },
  ];

  const handleItemClick = (id) => {
    setActiveItem(id);
  };

  const getIcon = (id) => {
    const isActive = activeItem === id;
    const color = isActive ? 'white' : '#3C5E23'; 

    switch (id) {
      case 'dashboard':
        return (
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.892 8.05191L11.3237 3.47437C10.5545 2.84187 9.44535 2.84187 8.67619 3.47437L3.10866 8.05191C2.64116 8.43608 2.41866 9.04271 2.527 9.6377L3.73956 16.2894C3.92039 17.2794 4.78288 17.9994 5.78871 17.9994H14.2103C15.217 17.9994 16.0795 17.2794 16.2595 16.2894L17.472 9.63689C17.582 9.04356 17.3595 8.43608 16.892 8.05191ZM11.667 15.2919H8.33368C7.98868 15.2919 7.70868 15.0119 7.70868 14.6669C7.70868 14.3219 7.98868 14.0419 8.33368 14.0419H11.667C12.012 14.0419 12.292 14.3219 12.292 14.6669C12.292 15.0119 12.012 15.2919 11.667 15.2919Z" fill={color}/>
          </svg>
        );
      case 'myFields':
        return (
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0007 2.79102C6.70423 2.79102 3.20898 6.28627 3.20898 10.5827C3.20898 15.1504 7.45224 17.9536 10.26 19.808L10.7458 20.1307C10.8228 20.182 10.9117 20.2077 10.9997 20.2077C11.0877 20.2077 11.1767 20.182 11.2537 20.1307L11.7395 19.808C14.5472 17.9536 18.7905 15.1504 18.7905 10.5827C18.7923 6.28627 15.2971 2.79102 11.0007 2.79102ZM11.0007 12.8743C9.73474 12.8743 8.70898 11.8486 8.70898 10.5827C8.70898 9.31677 9.73474 8.29102 11.0007 8.29102C12.2666 8.29102 13.2923 9.31677 13.2923 10.5827C13.2923 11.8486 12.2666 12.8743 11.0007 12.8743Z" fill={color}/>
          </svg>
        );
        case 'search':
        return (
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9417 17.5583L14.3008 13.9183C15.2575 12.7642 15.8333 11.2833 15.8333 9.66667C15.8333 5.985 12.8483 3 9.16667 3C5.485 3 2.5 5.985 2.5 9.66667C2.5 13.3483 5.485 16.3333 9.16667 16.3333C10.7825 16.3333 12.2642 15.7575 13.4183 14.8017L17.0583 18.4417C17.1833 18.5667 17.3417 18.625 17.5 18.625C17.6583 18.625 17.8167 18.5667 17.9417 18.4417C18.1834 18.2 18.1834 17.8 17.9417 17.5583Z" fill={color}/>
         </svg>
        );
        case 'reports':
        return (
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M14.6875 3H5.3125C3.4375 3 2.5 3.9375 2.5 5.8125V15.1875C2.5 17.0625 3.4375 18 5.3125 18H14.6875C16.5625 18 17.5 17.0625 17.5 15.1875V5.8125C17.5 3.9375 16.5625 3 14.6875 3ZM7.29167 14.6667C7.29167 15.0117 7.01167 15.2917 6.66667 15.2917C6.32167 15.2917 6.04167 15.0117 6.04167 14.6667V13.4167C6.04167 13.0717 6.32167 12.7917 6.66667 12.7917C7.01167 12.7917 7.29167 13.0717 7.29167 13.4167V14.6667ZM10.625 14.6667C10.625 15.0117 10.345 15.2917 10 15.2917C9.655 15.2917 9.375 15.0117 9.375 14.6667V12.1667C9.375 11.8217 9.655 11.5417 10 11.5417C10.345 11.5417 10.625 11.8217 10.625 12.1667V14.6667ZM13.9583 14.6667C13.9583 15.0117 13.6783 15.2917 13.3333 15.2917C12.9883 15.2917 12.7083 15.0117 12.7083 14.6667V10.5C12.7083 10.155 12.9883 9.875 13.3333 9.875C13.6783 9.875 13.9583 10.155 13.9583 10.5V14.6667ZM13.9583 8.16663C13.9583 8.51163 13.6783 8.79163 13.3333 8.79163C12.9883 8.79163 12.7083 8.51163 12.7083 8.16663V7.84172L11.2092 9.34074C10.7375 9.81324 9.91248 9.81241 9.44082 9.34074L8.37499 8.27506L7.10836 9.54175C6.98669 9.66341 6.82667 9.72506 6.66667 9.72506C6.50667 9.72506 6.34664 9.66425 6.22498 9.54175C5.98081 9.29758 5.98081 8.90172 6.22498 8.65755L7.49166 7.39087C7.97916 6.90337 8.7725 6.9042 9.26 7.39087L10.3258 8.45675L11.825 6.95752H11.5C11.155 6.95752 10.875 6.67752 10.875 6.33252C10.875 5.98752 11.155 5.70752 11.5 5.70752H13.3333C13.415 5.70752 13.4958 5.72407 13.5725 5.75574C13.725 5.81907 13.8467 5.94074 13.91 6.09408C13.9417 6.17074 13.9583 6.25167 13.9583 6.33333V8.16663Z" fill={color}/>
         </svg>
        );
        case 'integration':
        return (
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.0827 10.5H15.8327C14.9118 10.5 14.166 11.2458 14.166 12.1667C14.166 13.0875 14.9118 13.8333 15.8327 13.8333H17.0827C17.3127 13.8333 17.4993 14.02 17.4993 14.25V16.3333C17.4993 17.2542 16.7535 18 15.8327 18H12.916C12.686 18 12.4993 17.8133 12.4993 17.5833V16.3333C12.4993 15.4125 11.7535 14.6667 10.8327 14.6667C9.91185 14.6667 9.16602 15.4125 9.16602 16.3333V17.5833C9.16602 17.8133 8.97935 18 8.74935 18H6.66602C5.74518 18 4.99935 17.2542 4.99935 16.3333V14.25C4.99935 14.02 4.81268 13.8333 4.58268 13.8333H3.33268C2.41185 13.8333 1.66602 13.0875 1.66602 12.1667C1.66602 11.2458 2.41185 10.5 3.33268 10.5H4.58268C4.81268 10.5 4.99935 10.3133 4.99935 10.0833V8C4.99935 7.07917 5.74518 6.33333 6.66602 6.33333H8.74935C8.97935 6.33333 9.16602 6.14667 9.16602 5.91667V4.66667C9.16602 3.74583 9.91185 3 10.8327 3C11.7535 3 12.4993 3.74583 12.4993 4.66667V5.91667C12.4993 6.14667 12.686 6.33333 12.916 6.33333H15.8327C16.7535 6.33333 17.4993 7.07917 17.4993 8V10.0833C17.4993 10.3133 17.3127 10.5 17.0827 10.5Z" fill={color}/>
          </svg>
        );
        case 'myProfile':
        return (
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.67419 5.91732C6.67419 4.07898 8.16919 2.58398 10.0075 2.58398C11.8459 2.58398 13.3409 4.07898 13.3409 5.91732C13.3409 7.75565 11.8459 9.25065 10.0075 9.25065C8.16919 9.25065 6.67419 7.75565 6.67419 5.91732ZM11.6667 10.9173H8.33333C4.95 10.9173 3.75 13.3949 3.75 15.5165C3.75 17.414 4.75921 18.4173 6.66921 18.4173H13.3308C15.2408 18.4173 16.25 17.414 16.25 15.5165C16.25 13.3949 15.05 10.9173 11.6667 10.9173Z" fill={color}/>
         </svg>

        );
        case 'summaries':
        return (
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1673 3H5.83398C4.16732 3 3.33398 3.83333 3.33398 5.5V15.5C3.33398 17.1667 4.16732 18 5.83398 18H14.1673C15.834 18 16.6673 17.1667 16.6673 15.5V5.5C16.6673 3.83333 15.834 3 14.1673 3ZM10.834 14.4583H6.66732C6.32232 14.4583 6.04232 14.1783 6.04232 13.8333C6.04232 13.4883 6.32232 13.2083 6.66732 13.2083H10.834C11.179 13.2083 11.459 13.4883 11.459 13.8333C11.459 14.1783 11.179 14.4583 10.834 14.4583ZM13.334 11.125H6.66732C6.32232 11.125 6.04232 10.845 6.04232 10.5C6.04232 10.155 6.32232 9.875 6.66732 9.875H13.334C13.679 9.875 13.959 10.155 13.959 10.5C13.959 10.845 13.679 11.125 13.334 11.125ZM13.334 7.79167H6.66732C6.32232 7.79167 6.04232 7.51167 6.04232 7.16667C6.04232 6.82167 6.32232 6.54167 6.66732 6.54167H13.334C13.679 6.54167 13.959 6.82167 13.959 7.16667C13.959 7.51167 13.679 7.79167 13.334 7.79167Z" fill={color}/>
          </svg>
        );
        case 'anomalyDetection':
        return (
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.6875 3H5.3125C3.4375 3 2.5 3.9375 2.5 5.8125V15.1875C2.5 17.0625 3.4375 18 5.3125 18H14.6875C16.5625 18 17.5 17.0625 17.5 15.1875V5.8125C17.5 3.9375 16.5625 3 14.6875 3ZM9.375 6.75C9.375 6.405 9.655 6.125 10 6.125C10.345 6.125 10.625 6.405 10.625 6.75V10.5592C10.625 10.9042 10.345 11.1842 10 11.1842C9.655 11.1842 9.375 10.9042 9.375 10.5592V6.75ZM10.0167 14.25C9.55668 14.25 9.17908 13.8767 9.17908 13.4167C9.17908 12.9567 9.54834 12.5833 10.0083 12.5833H10.0167C10.4775 12.5833 10.85 12.9567 10.85 13.4167C10.85 13.8767 10.4767 14.25 10.0167 14.25Z" fill={color}/>
          </svg>
        );
      
      default:
        return null;
    }
  };

  return (
    
    <div className="w-[17%] bg-slate-50 ">
      <div className="p-5">
        <h1 className="text-xl font-normal ml-2 pl-2 border-b pb-5">LOGO</h1>
      </div>
      <div className="px-5 space-y-1 mt-2">
        {menuItems.map((item) => (
          <div
            key={item.id}
            onClick={() => handleItemClick(item.id)}
            className={`flex items-center p-3 rounded-lg cursor-pointer 
              ${activeItem === item.id ? 'bg-customGreen text-white' : 'text-gray-700'}`}
          >
            {getIcon(item.id)}
            <span className="ml-2">{item.name}</span>
          </div>
        ))}
      </div>
      <div className=" px-4 space-y-9 mt-24">

       <div className='px-5 text-gray-500 '>
       <svg width="22" className='inline mr-2' height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M11 1.875C5.68477 1.875 1.375 6.18477 1.375 11.5C1.375 16.8152 5.68477 21.125 11 21.125C16.3152 21.125 20.625 16.8152 20.625 11.5C20.625 6.18477 16.3152 1.875 11 1.875ZM11 19.4922C6.58711 19.4922 3.00781 15.9129 3.00781 11.5C3.00781 7.08711 6.58711 3.50781 11 3.50781C15.4129 3.50781 18.9922 7.08711 18.9922 11.5C18.9922 15.9129 15.4129 19.4922 11 19.4922Z" fill="#718096"/>
       <path d="M13.3977 7.3041C12.7531 6.73906 11.9023 6.42969 11 6.42969C10.0977 6.42969 9.24687 6.74121 8.60234 7.3041C7.93203 7.89063 7.5625 8.6791 7.5625 9.52344V9.68672C7.5625 9.78125 7.63984 9.85859 7.73437 9.85859H8.76562C8.86016 9.85859 8.9375 9.78125 8.9375 9.68672V9.52344C8.9375 8.57598 9.86348 7.80469 11 7.80469C12.1365 7.80469 13.0625 8.57598 13.0625 9.52344C13.0625 10.1916 12.5898 10.8039 11.8572 11.0854C11.4018 11.2594 11.015 11.5645 10.7379 11.9641C10.4564 12.3723 10.3104 12.8621 10.3104 13.3584V13.8203C10.3104 13.9148 10.3877 13.9922 10.4822 13.9922H11.5135C11.608 13.9922 11.6854 13.9148 11.6854 13.8203V13.3326C11.6865 13.124 11.7504 12.9207 11.8688 12.749C11.9872 12.5773 12.1547 12.4453 12.3492 12.3701C13.6168 11.8824 14.4354 10.7652 14.4354 9.52344C14.4375 8.6791 14.068 7.89063 13.3977 7.3041ZM10.1406 16.2266C10.1406 16.4545 10.2312 16.6731 10.3923 16.8342C10.5535 16.9954 10.7721 17.0859 11 17.0859C11.2279 17.0859 11.4465 16.9954 11.6077 16.8342C11.7688 16.6731 11.8594 16.4545 11.8594 16.2266C11.8594 15.9986 11.7688 15.7801 11.6077 15.6189C11.4465 15.4577 11.2279 15.3672 11 15.3672C10.7721 15.3672 10.5535 15.4577 10.3923 15.6189C10.2312 15.7801 10.1406 15.9986 10.1406 16.2266Z" fill="#718096"/>
      </svg>
          Get Help
       </div>

       <div className='px-5 text-gray-500 '>
       <svg width="22" height="23" className='inline mr-2' viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M9.46458 4.45725C9.85508 2.84758 12.1449 2.84758 12.5354 4.45725C12.594 4.69907 12.7089 4.92363 12.8707 5.11267C13.0324 5.3017 13.2366 5.44987 13.4664 5.5451C13.6963 5.64033 13.9454 5.67995 14.1935 5.66071C14.4415 5.64148 14.6816 5.56394 14.894 5.43442C16.3084 4.57275 17.9282 6.19158 17.0665 7.60692C16.9372 7.81927 16.8597 8.05914 16.8406 8.30704C16.8214 8.55494 16.8609 8.80387 16.9561 9.0336C17.0512 9.26332 17.1992 9.46736 17.388 9.62913C17.5768 9.7909 17.8012 9.90583 18.0428 9.96458C19.6524 10.3551 19.6524 12.6449 18.0428 13.0354C17.8009 13.094 17.5764 13.2089 17.3873 13.3707C17.1983 13.5324 17.0501 13.7366 16.9549 13.9664C16.8597 14.1963 16.8201 14.4454 16.8393 14.6935C16.8585 14.9415 16.9361 15.1816 17.0656 15.394C17.9273 16.8084 16.3084 18.4282 14.8931 17.5665C14.6807 17.4372 14.4409 17.3597 14.193 17.3406C13.9451 17.3214 13.6961 17.3609 13.4664 17.4561C13.2367 17.5512 13.0326 17.6992 12.8709 17.888C12.7091 18.0768 12.5942 18.3012 12.5354 18.5428C12.1449 20.1524 9.85508 20.1524 9.46458 18.5428C9.40599 18.3009 9.29113 18.0764 9.12935 17.8873C8.96757 17.6983 8.76344 17.5501 8.53357 17.4549C8.3037 17.3597 8.0546 17.3201 7.80653 17.3393C7.55846 17.3585 7.31844 17.4361 7.106 17.5656C5.69158 18.4273 4.07183 16.8084 4.9335 15.3931C5.06284 15.1807 5.14025 14.9409 5.15944 14.693C5.17863 14.4451 5.13906 14.1961 5.04393 13.9664C4.94881 13.7367 4.80082 13.5326 4.612 13.3709C4.42318 13.2091 4.19885 13.0942 3.95725 13.0354C2.34758 12.6449 2.34758 10.3551 3.95725 9.96458C4.19907 9.90599 4.42363 9.79113 4.61267 9.62935C4.8017 9.46757 4.94987 9.26344 5.0451 9.03357C5.14033 8.8037 5.17995 8.5546 5.16071 8.30653C5.14148 8.05846 5.06394 7.81844 4.93442 7.606C4.07275 6.19158 5.69158 4.57183 7.10692 5.4335C8.02358 5.99083 9.21158 5.49767 9.46458 4.45725Z" stroke="#718096" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
     <path d="M11 14.25C12.5188 14.25 13.75 13.0188 13.75 11.5C13.75 9.98122 12.5188 8.75 11 8.75C9.48122 8.75 8.25 9.98122 8.25 11.5C8.25 13.0188 9.48122 14.25 11 14.25Z" stroke="#718096" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
        Setting
       </div>
      </div>
    </div>
  );
};

export default Sidebar;
