import React, { useState, useEffect } from "react";
import ReactSpeedometer from "react-d3-speedometer";

const SpeedometerComponent = () => {
  const minValue = 400; 
  const maxValue = 800; 
  const initialValue = 550; 
  const [value, setValue] = useState(initialValue); 

  const calculatePercentage = (value) => {
    return (value - minValue) / (maxValue - minValue);
  };

  const getSegmentStops = () => {
    return [minValue, value, maxValue]; 
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => {
        
        if (prevValue < initialValue) {
          return Math.min(prevValue + 5, initialValue); 
        } else if (prevValue > initialValue) {
          return Math.max(prevValue - 5, initialValue); 
        }
        return prevValue; 
      });
    }, 100); 

    return () => clearInterval(interval); 
  }, []);

  return (
    <>
    <div className="flex justify-center items-center">
      <ReactSpeedometer
        value={value}
        minValue={minValue} 
        maxValue={maxValue}
        segments={1}
        needleColor="green"
        customSegmentStops={getSegmentStops()} 
        segmentColors={["green", "lightgrey"]} 
        currentValueText={` ${value} tons`}
        ringWidth={10}
        textColor="#000000"  
        needleHeightRatio={0.65}
        width={180}
        height={118}
        valueTextFontSize={18}
        paddingVertical={17}
        labelFontSize={0}
      />
      </div>
        <p className="text-slate-400 text-[14px] text-center ">From 400 tons </p>
    </>
  );
};

export default SpeedometerComponent;
