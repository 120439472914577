import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon,
  useMapEvents,
  Polyline,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import GeoContext from "contexts/GeoContext";
import { mapIcon, mapMidIcon } from "constants/index";
import { getMidPoints, getZoomLevel } from "utils";
import { getAreaOfPolygon } from "geolib";
import LayersIcon from "resources/fields/layers.svg";
import MagnifierIcon from "resources/fields/magnifier.svg";
import AnalyticsIcon from "resources/fields/analytics.svg";
import ToggleBackgroundSatellite from "resources/fields/toggleS.png";
import ToggleBackgroundMap from "resources/fields/toggleM.png";
import { geocodeAddress } from "apis/positionstack";
import { RingLoader } from "react-spinners";
import toast from "react-hot-toast";
import { getAutoBoundaries } from "apis/backend";

function doSegmentsIntersect(p1, p2, p3, p4) {
  const ccw = (a, b, c) =>
    (c[1] - a[1]) * (b[0] - a[0]) > (b[1] - a[1]) * (c[0] - a[0]);

  return (
    ccw(p1, p3, p4) !== ccw(p2, p3, p4) && ccw(p1, p2, p3) !== ccw(p1, p2, p4)
  );
}

function isInprogressPolygonSelfIntersecting(polygon) {
  const n = polygon.length;
  if (n < 4) return false;

  if (
    polygon[0][0] === polygon[n - 1][0] &&
    polygon[0][1] === polygon[n - 1][1]
  )
    return false;

  for (let j = 0; j < n - 3; j++) {
    if (
      doSegmentsIntersect(
        polygon[n - 2],
        polygon[n - 1],
        polygon[j],
        polygon[j + 1]
      )
    ) {
      return true;
    }
  }
  return false;
}

function isPolygonSelfIntersecting(polygon) {
  const n = polygon.length;
  for (let i = 0; i < n; i++) {
    for (let j = i + 2; j < n; j++) {
      if (i === 0 && j === n - 1) continue;
      if (
        doSegmentsIntersect(
          polygon[i],
          polygon[(i + 1) % n],
          polygon[j],
          polygon[(j + 1) % n]
        )
      ) {
        return true;
      }
    }
  }
  return false;
}

const SearchBar = ({ onSubmit }) => {
  const [results, setResults] = useState(null);

  const [searchError, setSearchError] = useState(null);

  const address = useRef(null);

  const [loader, setLoader] = useState(false);

  const timeoutRef = useRef(null);

  const handleClick = (result) => {
    const { latitude, longitude, type } = result;

    const zoom = getZoomLevel(type);

    onSubmit(latitude, longitude, zoom);

    setResults(null);
  };

  const formatSearch = (search) => {
    const name = search.name || "Unknown Place";
    const region = search.region || "";
    const country = search.country || "Unknown Country";

    const summary = region
      ? `${name}, ${region}, ${country}`
      : `${name}, ${country}`;

    return summary;
  };

  const handleSearch = async () => {
    if (!address.current || address.current === "") return;
    setLoader(true);
    setSearchError(null);
    setResults(null);
    try {
      const results = (await geocodeAddress(address.current)).data;
      if (!results.length) {
        setSearchError("No results found!");
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setSearchError(null), 3000);
        setLoader(false);
        return;
      }

      const topResults = results.slice(0, 6);
      if (topResults.length === 1) handleClick(topResults[0]);
      else setResults(topResults);

      setLoader(false);
    } catch (e) {
      toast.error("Something went wrong finding this address!");
      setSearchError("Something went wrong finding this address!");
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setSearchError(null), 3000);
      setLoader(false);
    }
  };

  return (
    <div
      className={`absolute z-max top-5 w-full h-full flex justify-center select-none ${results || searchError ? "pointer-events-auto" : "pointer-events-none"}`}
      onClick={(e) =>
        setResults(null) ||
        setSearchError(null) ||
        (timeoutRef.current && clearTimeout(timeoutRef.current))
      }
    >
      <div className="relative">
        <div
          className={`w-[521px] h-10 flex gap-2 backdrop-blur-[2px] items-center ${results || searchError ? "rounded-tl-lg rounded-tr-lg border-b border-green-700" : "rounded-lg"} bg-white bg-opacity-60 px-4 py-4 pointer-events-auto`}
        >
          <img src={MagnifierIcon} alt="" className="w-5 h-5" />
          <input
            className="w-full bg-white bg-opacity-0 px-1 focus:outline-none"
            onChange={(e) => (address.current = e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          />
          {loader && <RingLoader size={24} color={"darkgreen"} />}
        </div>
        {results && (
          <div className="absolute top-10 w-[521px]">
            {results.map((result) => (
              <div
                className="w-full pl-11 py-2 backdrop-blur-[2px] bg-white hover:bg-green-700 bg-opacity-60 hover:text-white cursor-pointer pointer-events-auto"
                onClick={() => handleClick(result)}
              >
                {formatSearch(result)}
              </div>
            ))}
          </div>
        )}
        {searchError && (
          <div className="absolute top-10 w-[521px]">
            <div className="text-center w-full py-2 bg-white bg-opacity-60 text-red-600 cursor-pointer pointer-events-auto pl-1">
              {searchError}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MODE_TYPES = {
  NORMAL: 0,
  DRAW: 1,
  EDIT: 2,
  SELECTED: 3,
};

const Map = ({ onViewAnalytics }) => {
  const { points, setPoints } = useContext(GeoContext);

  const [cursorPosition, setCursorPosition] = useState([0, 0]);

  const [mapRef, setMapRef] = useState(null);

  const [midPoints, setMidPoints] = useState(getMidPoints(points));

  const [dragStartLocation, setDragStartLocation] = useState(null);

  const [mode, setMode] = useState(MODE_TYPES.EDIT);

  const [autoBoundaries, setAutoBoundaries] = useState([]);

  const handleFetchBoundaries = async () => {
    if (!mapRef) return;
    debugger;
    const { lat, lng } = mapRef.getCenter();
    const data = await getAutoBoundaries(lat, lng);
    const boundaries = data.map((boundary) => boundary.points.slice(0, -1));
    setAutoBoundaries(boundaries);
  };

  const error =
    points.length === 0
      ? false
      : mode === MODE_TYPES.DRAW
        ? isInprogressPolygonSelfIntersecting([...points, cursorPosition])
        : mode === MODE_TYPES.EDIT
          ? isPolygonSelfIntersecting(points)
          : false;

  useEffect(() => {
    setMidPoints(getMidPoints(points));
    if (mode === MODE_TYPES.EDIT && points.length < 3) {
      setPoints([]);
      setMode(MODE_TYPES.DRAW);
    }
  }, [mode, points, setPoints]);

  const [layer, setLayer] = useState("satellite");

  const toggleLayer = useCallback(() => {
    console.log(points);
    setLayer((prevLayer) => (prevLayer === "street" ? "satellite" : "street"));
    if (layer === "street") handleFetchBoundaries();
  }, [layer, handleFetchBoundaries, points]);

  const flyToLocation = useCallback(
    (lat, lon, zoom = null, animate = true) => {
      if (mapRef) {
        mapRef.flyTo([lat, lon], zoom || 12 || mapRef.getZoom(), {
          animate,
        });
      }
    },
    [mapRef]
  );

  const handleMapClick = useCallback(
    (event) => {
      if (mode !== MODE_TYPES.DRAW || error) return;
      const { lat, lng } = event.latlng;
      setPoints((prevPoints) => [...prevPoints, [lat, lng]]);
    },
    [mode, error, setPoints]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (mode === MODE_TYPES.DRAW)
        setCursorPosition([e.latlng.lat, e.latlng.lng]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [points, mode]
  );

  const handleClick = (index) => {
    if (mode === MODE_TYPES.DRAW) {
      if (index !== 0) return;
      if (points.length < 3) {
        if (points.length === 1) setPoints([]);
        return;
      }
      setMode(MODE_TYPES.EDIT);
    } else if (mode === MODE_TYPES.EDIT) {
      let updatedPoints = [...points];
      updatedPoints.splice(index, 1);
      setPoints(updatedPoints);
    }
  };

  const handleDrag = (idx, event) => {
    if (mode !== MODE_TYPES.EDIT) return;
    const { lat, lng } = event.target._latlng;
    const newPoints = [...points];
    newPoints[idx] = [lat, lng];
    setPoints(newPoints);
  };

  const handleDragStart = (idx, event) => {
    const { lat, lng } = event.target._latlng;
    setDragStartLocation([lat, lng]);
  };

  const handleDragEnd = (idx, event) => {
    if (error) {
      const newPoints = [...points];
      newPoints[idx] = [...dragStartLocation];
      setPoints(newPoints);
    }
  };

  const handleCursorFocus = (event) => {
    setCursorPosition([event.latlng.lat, event.latlng.lng]);
  };

  const handleMidPointClick = (idx) => {
    const midpoint = [...midPoints[idx]];

    const newPoints = [...points];
    if (points.length > 2 && idx + 1 === midPoints.length)
      newPoints.push(midpoint);
    else newPoints.splice(idx + 1, 0, midpoint);

    setMidPoints(getMidPoints(newPoints));

    setPoints(newPoints);
  };

  const handleFlyTo = () => {
    console.log(points);
    flyToLocation(43.93667839066947, -88.86729610068411, 20);
  };
  const handleUndo = useCallback(() => {
    let updatedPoints = [...points];
    updatedPoints.pop();
    setPoints(updatedPoints);
  }, [points, setPoints]);

  const handleClear = useCallback(() => {
    setPoints([]);
    setMode(MODE_TYPES.DRAW);
  }, [setPoints]);

  const MapEvents = () => {
    useMapEvents({
      click: handleMapClick,
      mousemove: handleMouseMove,
    });
    return null;
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault();
        handleUndo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleUndo]);

  useEffect(() => {
    if (mapRef) {
      if (mode === MODE_TYPES.DRAW)
        mapRef._container.classList.add("crosshair");
      else mapRef._container.classList.remove("crosshair");
    }
  }, [mode, mapRef]);

  const AutoBoundaries = useMemo(() => {
    if (!autoBoundaries.length) return <React.Fragment />;

    return autoBoundaries.map((boundary) => (
      <Polygon
        positions={boundary}
        color={"rgba(0, 0, 0, 0.4)"} // Border color (black, fully opaque)
        weight={1} // Border width in pixels
        opacity={0.4} // Border opacity (1 = fully opaque)
        fillColor={"rgba(255, 255, 0, 0.4)"} // Fill color
        fillOpacity={0.4} // Fill opacity
        eventHandlers={{
          click: () => {
            if (mode !== MODE_TYPES.DRAW) return;
            setTimeout(() => {
              setPoints(boundary);
              setMode(MODE_TYPES.EDIT);
            }, 0);
          },
        }}
      />
    ));
  }, [autoBoundaries, setPoints, mode]);

  const Analytics = useMemo(() => {
    if (mode !== MODE_TYPES.EDIT) return null;

    const polygon = points.map((point) => {
      return { latitude: point[0], longitude: point[1] };
    });

    const area =
      Math.round(0.00024711 * getAreaOfPolygon(polygon) * 1000) / 1000;

    let areaString;

    if (area > 100000000) {
      areaString = (area / 1000000000).toFixed(2) + " billion";
    } else if (area > 100000) {
      areaString = (area / 1000000).toFixed(2) + " million";
    } else if (area > 1000) {
      areaString = (area / 1000).toFixed(2) + "k";
    } else {
      areaString = area.toFixed(3); // Ensures 1 decimal place
    }

    return (
      <div className="absolute z-max top-20 w-full flex justify-center select-none pointer-events-none">
        <div className="h-[95px] flex justify-start items-center pointer-events-auto px-4 bg-white bg-opacity-70 backdrop-blur rounded-xl">
          <img src={AnalyticsIcon} alt="" className="w-14 h-14"></img>
          <div className="ml-2 mr-8 text-[#0D253C]">
            <div>Area Selected</div>
            <div className=" font-semibold text-xl">{areaString} Acres</div>
          </div>
          <div
            className="px-3 py-2 rounded-md bg-[#3C5E23] text-white cursor-pointer hover:opacity-95"
            onClick={onViewAnalytics}
          >
            View Analytics
          </div>
        </div>
      </div>
    );
  }, [points, mode]);

  const LayerToggler = useMemo(() => {
    return (
      <div
        className="absolute right-12 bottom-[28px] z-max border-white border-2 rounded-lg w-40 h-[60px] cursor-pointer flex items-center justify-center gap-2 select-none"
        onClick={handleFlyTo} //toggleLayer
        style={{
          backgroundImage: `url(${layer !== "satellite" ? ToggleBackgroundMap : ToggleBackgroundSatellite})`,
          backgroundSize: "cover",
        }}
      >
        <img
          src={LayersIcon}
          alt="="
          className={`w-9 h-9 rounded-full p-2 bg-white border-2 border-green-800`}
        />
        <div
          className={`text-sm ${layer !== "satellite" ? "text-green-800" : "text-white"}`}
        >
          Toggle Layers
        </div>
      </div>
    );
  }, [layer, toggleLayer, handleFlyTo]);

  const PointsCleaner = useMemo(() => {
    if (points.length < 1) return null;
    return (
      <button
        onClick={handleClear}
        className="border-2 border-white rounded-lg text-red-400 bg-black bg-opacity-60 py-1 px-2 hover:bg-opacity-100 pointer-events-auto z-max absolute top-2 right-2"
      >
        Clear Points
      </button>
    );
  }, [points, handleClear]);

  return (
    <div className={`w-full h-full`}>
      {PointsCleaner}
      {LayerToggler}
      <SearchBar onSubmit={flyToLocation} />
      {Analytics}

      <MapContainer
        center={[0, 0]}
        zoom={3}
        style={{ height: "100vh", width: "100%" }}
        ref={(ref) => setMapRef(ref)}
        zoomControl={false}
      >
        {layer === "street" && (
          <TileLayer
            url={`https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}`}
            maxZoom={20}
            subdomains={["mt1", "mt2", "mt3"]}
          />
        )}
        {layer === "satellite" && (
          <TileLayer
            url={`https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}`}
            maxZoom={20}
            subdomains={["mt1", "mt2", "mt3"]}
          />
        )}

        {points.map((position, idx) => (
          <Marker
            className="z-50"
            key={idx}
            position={position}
            icon={mapIcon}
            draggable={mode === MODE_TYPES.EDIT}
            eventHandlers={{
              click: () => handleClick(idx),
              drag: (event) => handleDrag(idx, event),
              dragstart: (event) => handleDragStart(idx, event),
              dragend: (event) => handleDragEnd(idx, event),
              mousemove: idx === 0 && ((event) => handleCursorFocus(event)),
            }}
          />
        ))}

        {mode === MODE_TYPES.EDIT &&
          midPoints.map((position, idx) => (
            <Marker
              className="z-50"
              key={idx}
              position={position}
              icon={mapMidIcon}
              eventHandlers={{
                click: () => handleMidPointClick(idx),
              }}
            />
          ))}
        {(mode === MODE_TYPES.DRAW || !error) && points.length >= 2 && (
          <Polygon
            positions={points}
            color={"rgba(0,0,0,0)"}
            fillColor={"rgba(0, 0, 255, 0.2)"}
            fillOpacity={0.5}
          />
        )}
        {mode === MODE_TYPES.EDIT && error && error && points.length >= 2 && (
          <Polygon
            positions={points}
            color={"rgba(0,0,0,0)"}
            fillColor={"rgba(255, 0, 0, 0.2)"}
            fillOpacity={0.5}
          />
        )}

        {mode === MODE_TYPES.EDIT && error && points.length > 0 && (
          <Polyline
            positions={
              mode === MODE_TYPES.DRAW ? points : [...points, points[0]]
            }
            color={"red"}
          />
        )}
        {(mode === MODE_TYPES.DRAW || !error) && points.length > 0 && (
          <Polyline
            positions={
              mode === MODE_TYPES.DRAW ? points : [...points, points[0]]
            }
            color={"blue"}
          />
        )}

        {!error && points.length > 0 && mode === MODE_TYPES.DRAW && (
          <Polyline
            positions={[points[points.length - 1], cursorPosition]}
            color={"blue"}
            dashArray={[8]}
            className="crosshair"
          />
        )}
        {error && points.length > 0 && mode === MODE_TYPES.DRAW && (
          <Polyline
            positions={[points[points.length - 1], cursorPosition]}
            color={"red"}
            dashArray={[8]}
            className="crosshair"
          />
        )}

        {AutoBoundaries}

        <MapEvents />
        <ZoomControl position="bottomright" />
      </MapContainer>
    </div>
  );
};

export default Map;
