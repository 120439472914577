import React from 'react';
import { CiSearch } from "react-icons/ci";
import { FaBell } from 'react-icons/fa';
import BasicMenu from './Dropdown';
import Rightside from './Rightside';

export default function DashboardIcons() {
  return (
   
    <div className='w-full '>
         <div className='h-12 mt-5 flex justify-between border-b'>
            <div className='flex justify-between items-center'>
            
                <div className='font-bold text-2xl ml-12 mb-5'>Dashboard</div>
            </div>
            <div className="flex items-center space-x-6 mb-5">
       <div className='rounded-full h-7 w-8 bg-slate-100 relative'>
       <CiSearch className=" absolute text-xl cursor-pointer left-[6px] top-[4px] " />
       </div>
        <div className="rounded-full h-7 w-8 bg-slate-100 relative">
          <FaBell className=" absolute cursor-pointer left-[7px] top-[7px]" />
        
        </div>
            <BasicMenu/>
      </div>
      
       
    </div>
    <Rightside/>
    </div>
   
  )
}
