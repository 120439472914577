import React, { useState, useEffect } from 'react';
import Golfimage from '../../resources/myfeilds/Golf Arena.jpeg';

const FieldsData = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [fields, setFields] = useState([]);

  
  useEffect(() => {
   
    fetch('http://localhost:4000/api/data')
      .then(response => response.json())
      .then(data => setFields(data)) 
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="w-[30%] relative border-r">
      <div className="py-2 px-2">
        <input
          type="text"
          placeholder="Search..."
          className="w-full px-3 py-2 mb-4 border border-slate-50 rounded-xl bg-slate-50 pl-10"
        />
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className="absolute top-[18px] left-4"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="9.80492"
            cy="9.80492"
            r="7.49047"
            stroke="#262B35"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.0156 15.4043L17.9523 18.3334"
            stroke="#262B35"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <h2 className="text-slate-500 mb-2 ml-3">For Season 2023</h2>
      <div className="space-y-2 h-[570px] overflow-y-auto cursor-pointer scroll-container">
        <div className="mr-2">
          {fields.map((field, index) => (
            <div
              key={field.id || index} 
              onClick={() => setSelectedId(field.id)}
              className={`flex items-center p-2 ${
                selectedId === field.id ? 'bg-slate-100 border-l-2 border-l-customGreen' : 'bg-white'
              }`}
            >
              <img
                src={field.image || Golfimage} 
                className="w-12 h-12 ml-2 rounded-md object-cover"
                alt={field.heading}
              />
              <div className="ml-4">
                <h3 className="text-sm font-medium">{field.heading}</h3>
                <p className="text-xs text-gray-500">{field.subheading}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FieldsData;
