import React from 'react'

export default function AdBanner() {
  return (
    <div className=''>
        <div className='w-64 mb-7 h-[520px] bg-gradient-to-l from-customGreen to-[#869337]  text-white flex justify-center items-center text-center'>
        Ad Banner
        </div>
    </div>
  )
}
