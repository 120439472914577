import React from 'react';
import Sidebar from './Leftside';
import Dashboard from './Dashboard';



export default function DashboardHeader() {
    return (
      <>
      <div className='w-full flex'>
      <Sidebar/>
       <Dashboard/>
        </div>
    
        </>
    );
}



