import React from 'react'
import Cards from './Cards'
import SpeedometerComponent from './SpeedoComp'
import AdBanner from './AdBanner'
import YearDropdown from './YearButton'
import CustomSegmentStops from './SpeedoMeterDes'





export default function Rightside() {
    
  return (
    <div className='bg-white h-auto'>
    <Cards/>
   <div className='mx-16 mt-4 relative'>
    <div className='flex justify-between'>
    <SpeedometerComponent/> 
    <SpeedometerComponent/> 
    <AdBanner/>
    </div>
    <div className='absolute top-72 bg-slate-50 w-[872px] h-[43%] flex justify-around'>
      <div className='font-bold flex-col pt-16 '>
        My Field Growth
      <div className='pt-2'><YearDropdown/></div>
      </div>
      <div> <CustomSegmentStops/></div>
     
    </div>
   </div>
    </div>
    
  )
}