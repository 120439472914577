import BasicMenu from 'dashbord/Dropdown';
import Sidebar from 'dashbord/Leftside';
import React from 'react';
import { CiSearch } from "react-icons/ci";
import { FaBell } from 'react-icons/fa';
import FieldsData from './FeildData';
import FeildOverviwe from './FeildOverviwe';


export default function MyFeildCom() {
  return (
    <div className='flex justify-between '>

<Sidebar className="bg-slate-50"/>

    <div className='w-[83%] m-'>
         <div className='h-12 mt-5 flex justify-between border-b'>
            <div className='flex justify-between items-center'>
            
                <div className='font-bold text-2xl ml-7 mb-5'>My Feilds</div>
                <button className="bg-customGreen text-white  px-1 py-1 ml-5 mb-4 rounded-md">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.416 10.0827H11.916V4.58268C11.916 4.07668 11.5053 3.66602 10.9993 3.66602C10.4933 3.66602 10.0827 4.07668 10.0827 4.58268V10.0827H4.58268C4.07668 10.0827 3.66602 10.4933 3.66602 10.9993C3.66602 11.5053 4.07668 11.916 4.58268 11.916H10.0827V17.416C10.0827 17.922 10.4933 18.3327 10.9993 18.3327C11.5053 18.3327 11.916 17.922 11.916 17.416V11.916H17.416C17.922 11.916 18.3327 11.5053 18.3327 10.9993C18.3327 10.4933 17.922 10.0827 17.416 10.0827Z" fill="white"/>
              </svg>

                </button>
            </div>
            <div className="flex items-center space-x-6 mb-5">
       <div className='rounded-full h-7 w-8 bg-slate-100 relative'>
       <CiSearch className=" absolute text-xl cursor-pointer left-[6px] top-[4px] " />
       </div>
        <div className="rounded-full h-7 w-8 bg-slate-100 relative">
          <FaBell className=" absolute cursor-pointer left-[7px] top-[7px]" />
         
        </div>
            <BasicMenu/>
      </div>
    </div>
    <div className='flex '>
    <FieldsData/>
    <FeildOverviwe/>
    </div>
   
    
    </div>
    </div>
  )
}
