import React from 'react'

export default function FieldStatistics() {

    const statisticsData = [
        { image:'', name: 'Surface Water', percentage: 75 },
        { image:'', name: 'Crop Growth', percentage: 30},
        { image:'', name: 'Pest & Diseases', percentage: 75},
        { image:'', name: 'Soil Moisture', percentage: 75},
        { image:'', name: 'Nitrogen Prescription', percentage: 30},
        { image:'', name: 'Burned Land', percentage: 30}
    ];

  return (
    <div className='border mt-4 rounded-xl'>
        <div className='p-3 flex justify-between'>
           <p className='font-bold py-2'>Statistics</p>
           <button className="text-sm  px-3 py-2 bg-slate-50 rounded-md">View on Map <svg width="6" height="10" className='inline font-bold' viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 9L5 5L1 1" stroke="#262B35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
         </button> 

        </div>
    </div>
  )
}