import { useEffect, useState } from "react";
import Input from "components/common/CustomInput";
import CustomButton from "components/common/CustomButton";
import GoogleButton from "components/common/GoogleButton";
import { isUserLoggedIn, getUserInfo, validate, findMessage } from "utils";
import { forgotPassword, googleLogin, login, signup } from "apis/backend";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import axios from "apis/backend/axios";

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(null);
  const [googleLoader, setGoogleLoader] = useState(false);

  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get("token");

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      toast.success("Google authentication successful!");
      axios.defaults.headers.common["Authorization"] =
        localStorage.getItem("token");
      setIsLogin(!isLogin);
    }
  }, [token]);

  const [data, setData] = useState({
    name: "",
    email: "",
    pass: "",
    confirmPass: "",
  });

  const handleLogin = async () => {
    setError(null);
    setLoader(true);
    try {
      let validation = validate(data, isLogin);
      if (validation) {
        setLoader(false);
        setError(validation);
        return;
      }
      const { name, email, pass } = data;

      let response;

      if (isLogin) response = await login(email, pass);
      else response = await signup(name, email, pass);
      localStorage.token = response.token;
      setLoader(false);
      window.location.reload();
    } catch (e) {
      setLoader(false);
      setError(findMessage(e) || "Something went wrong!");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const handleGoogleLogin = async () => {
    if (googleLoader) return;
    setGoogleLoader(true);
    try {
      const link = await googleLogin();
      window.location.href = link;
      setGoogleLoader(false);
    } catch (e) {
      setGoogleLoader(false);
    }
  };

  const handleForgotPassword = async () => {
    setError(null);
    setLoader(true);
    try {
      let validation = validate(data, isLogin, true);
      if (validation) {
        setLoader(false);
        setError(validation);
        return;
      }
      const { email } = data;
      await forgotPassword(email);
      setLoader(false);
      toast.success("Instructions sent to your email address");
    } catch (e) {
      setLoader(false);
      setError(findMessage(e) || "Something went wrong!");
    }
  };

  useEffect(() => {
    const handleEnterPress = (e) => {
      if (e.key === "Enter") {
        isForgotPassword ? handleForgotPassword() : handleLogin();
      }
    };

    window.addEventListener("keydown", handleEnterPress);

    return () => {
      window.removeEventListener("keydown", handleEnterPress);
    };
  }, [isForgotPassword, data]);

  const isLoggedIn = isUserLoggedIn();

  const userInfo = getUserInfo();

  if (userInfo === "ERROR") {
    toast.error("Login token corrupted! Login again...");
  }

  if (isLoggedIn)
    return (
      <div className="flex flex-col items-center text-white pb-10 pt-2">
        <div className="mt-4">
          <div className="text-4xl text-center font-semibold mt-8 mb-5 border-b-2 border-gray-300 pb-2">
            Your Account
          </div>
          <div className="text-lg mb-1 w-full text-gray-200">
            Username:{" "}
            <span className="font-semibold text-white">{userInfo.name}</span>
          </div>
          <div className="text-lg mb-1 text-gray-200">
            Email:{" "}
            <span className="font-semibold text-white">{userInfo.email}</span>
          </div>
          <div className="text-lg text-gray-200">
            Current Subscription:{" "}
            <span className="font-semibold text-white">
              {userInfo.subscription}
            </span>
          </div>
          <CustomButton
            text={"Logout"}
            className="!bg-red-600 hover:!bg-red-700"
            onClick={handleLogout}
          />
        </div>
      </div>
    );

  return (
    <div className="flex flex-col items-center text-white pb-40 pt-2">
      <div className="text-4xl font-medium mt-6 mb-8">
        Login to access your account
      </div>
      <div className="flex flex-col items-center bg-black bg-opacity-25 backdrop-blur-md rounded-2xl px-8 pb-4">
        <div className="flex mt-8 mb-4">
          <div
            className={`p-4 ${!isLogin ? "bg-[#3E3E3E] bg-opacity-10" : isForgotPassword ? "bg-red-600 bg-opacity-40" : "bg-green-600 bg-opacity-40"} border border-gray-800 border-opacity-40 border-r-0 rounded-l-lg text-xl cursor-pointer transition-colors duration-500`}
            onClick={() =>
              (isForgotPassword
                ? setIsForgotPassword(false)
                : setIsLogin(true)) || setError(null)
            }
          >
            Login
          </div>
          <div
            className={`p-4 ${isLogin ? "bg-[#3E3E3E] bg-opacity-10" : "bg-green-600 bg-opacity-40"} border border-gray-800 border-opacity-40 border-l-0 rounded-r-lg text-xl cursor-pointer transition-colors duration-500`}
            onClick={() =>
              (isForgotPassword
                ? setIsForgotPassword(false) || setIsLogin(false)
                : setIsLogin(false)) || setError(null)
            }
          >
            Signup
          </div>
        </div>
        <div className="flex flex-col">
          <Input
            className="w-[300px]"
            placeholder={"Name"}
            hidden={isLogin || isForgotPassword}
            onChange={(text) => setData({ ...data, name: text })}
          />
          <Input
            className="w-[300px]"
            placeholder={"Email"}
            onChange={(text) => setData({ ...data, email: text })}
          />
          <Input
            type={"password"}
            className="w-[300px]"
            placeholder={"Password"}
            onChange={(text) => setData({ ...data, pass: text })}
            hidden={isForgotPassword}
          />
          <Input
            type={"password"}
            className="w-[300px]"
            placeholder={"Confirm Password"}
            onChange={(text) => setData({ ...data, confirmPass: text })}
            hidden={isLogin || isForgotPassword}
          />
          <CustomButton
            text={
              isLogin ? (isForgotPassword ? "Send Code" : "Login") : "Signup"
            }
            loading={loader}
            onClick={isForgotPassword ? handleForgotPassword : handleLogin}
          />
          <div className="text-red-500 text-center text-sm mt-1">{error}</div>
          <div
            className={`cursor-pointer ml-1 text-sm text-gray-100 hover:text-gray-300 w-fit transition-all duration-300 overflow-hidden ${isForgotPassword || !isLogin ? "max-h-0 opacity-0 pointer-events-none" : "max-h-[50px] opacity-100 pointer-events-auto"}`}
            onClick={() => setIsForgotPassword(true)}
          >
            Forgot Password
          </div>

          <GoogleButton
            text={isLogin ? "Login with Google" : "Signup with Google"}
            onClick={handleGoogleLogin}
            hidden={isForgotPassword}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
