import React from 'react';

export default function Cards() {
  const Carddata = [
    { 
        title: 'Total Fields', 
        value: 4817, 
        Icon: (<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.4654 13.3494L23.2902 13.1086C22.9837 12.7364 22.6224 12.4408 22.2064 12.2219C21.6482 11.9044 21.0132 11.7402 20.3564 11.7402H6.72737C6.07055 11.7402 5.44657 11.9044 4.87733 12.2219C4.45039 12.4518 4.06725 12.7693 3.74979 13.1633C3.12581 13.9625 2.83024 14.9477 2.92876 15.9329L3.3338 21.0452C3.47611 22.5887 3.66221 24.4935 7.13241 24.4935H19.9623C23.4325 24.4935 23.6077 22.5887 23.7609 21.0342L24.166 15.9439C24.2645 15.0243 24.0237 14.1048 23.4654 13.3494ZM16.1637 19.3922H10.9201C10.4931 19.3922 10.1538 19.0419 10.1538 18.6259C10.1538 18.2099 10.4931 17.8596 10.9201 17.8596H16.1637C16.5906 17.8596 16.93 18.2099 16.93 18.6259C16.93 19.0528 16.5906 19.3922 16.1637 19.3922Z" fill="url(#paint0_linear_506_344)"/>
            <path d="M22.9176 9.82073C22.9587 10.2398 22.504 10.5204 22.1047 10.3867C21.5522 10.2018 20.9705 10.1093 20.3657 10.1093H6.72571C6.11614 10.1093 5.51596 10.2079 4.95595 10.396C4.56143 10.5285 4.10938 10.2571 4.10938 9.84093V7.70092C4.10938 3.79283 5.3026 2.59961 9.21068 2.59961H10.5024C12.0679 2.59961 12.5605 3.10317 13.1954 3.9242L14.509 5.67572C14.7827 6.04792 14.7937 6.06981 15.2753 6.06981H17.8807C21.3024 6.06981 22.6394 6.98634 22.9176 9.82073Z" fill="url(#paint1_linear_506_344)"/>
            <defs>
            <linearGradient id="paint0_linear_506_344" x1="5.10559" y1="15.2398" x2="19.3683" y2="22.5921" gradientUnits="userSpaceOnUse">
            <stop stop-color="#869337"/>
            <stop offset="1" stop-color="#193B2A"/>
            </linearGradient>
            <linearGradient id="paint1_linear_506_344" x1="6.05556" y1="4.84053" x2="16.6589" y2="12.4074" gradientUnits="userSpaceOnUse">
            <stop stop-color="#869337"/>
            <stop offset="1" stop-color="#193B2A"/>
            </linearGradient>
            </defs>
            </svg>
            )
    },
    { 
        title: 'Total Analysis', 
        value: 1627, 
        Icon: (<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2057 2.59961C7.33757 2.59961 5.00586 4.93132 5.00586 7.79944C5.00586 10.6128 7.20621 12.8898 10.0743 12.9883C10.1619 12.9774 10.2495 12.9774 10.3152 12.9883C10.3371 12.9883 10.348 12.9883 10.3699 12.9883C10.3808 12.9883 10.3808 12.9883 10.3918 12.9883C13.1942 12.8898 15.3946 10.6128 15.4055 7.79944C15.4055 4.93132 13.0738 2.59961 10.2057 2.59961Z" fill="url(#paint0_linear_506_350)"/>
            <path d="M15.7678 15.9002C12.7136 13.864 7.73267 13.864 4.65656 15.9002C3.26629 16.8307 2.5 18.0896 2.5 19.436C2.5 20.7825 3.26629 22.0305 4.64561 22.95C6.1782 23.9791 8.19245 24.4936 10.2067 24.4936C12.2209 24.4936 14.2352 23.9791 15.7678 22.95C17.1471 22.0195 17.9134 20.7716 17.9134 19.4141C17.9024 18.0677 17.1471 16.8197 15.7678 15.9002Z" fill="url(#paint1_linear_506_350)"/>
            <path d="M22.2376 8.44597C22.4128 10.5697 20.9021 12.4307 18.8112 12.6825C18.8003 12.6825 18.8003 12.6825 18.7893 12.6825H18.7565C18.6908 12.6825 18.6251 12.6825 18.5704 12.7044C17.5085 12.7591 16.5342 12.4197 15.8008 11.7958C16.9283 10.7886 17.5742 9.27794 17.4428 7.63589C17.3662 6.74918 17.0597 5.9391 16.5999 5.24944C17.0159 5.04145 17.4976 4.91008 17.9902 4.8663C20.1358 4.6802 22.0515 6.27846 22.2376 8.44597Z" fill="url(#paint2_linear_506_350)"/>
            <path d="M24.4256 18.5715C24.338 19.6334 23.6593 20.5529 22.5208 21.1769C21.4261 21.779 20.0468 22.0636 18.6784 22.0308C19.4666 21.3192 19.9264 20.4325 20.0139 19.4911C20.1234 18.1336 19.4775 16.8309 18.1858 15.791C17.4523 15.2108 16.5985 14.751 15.668 14.4116C18.0873 13.711 21.1305 14.1818 23.0025 15.6924C24.0096 16.5025 24.5241 17.5206 24.4256 18.5715Z" fill="url(#paint3_linear_506_350)"/>
            <defs>
            <linearGradient id="paint0_linear_506_350" x1="6.07892" y1="5.45032" x2="14.1321" y2="7.9412" gradientUnits="userSpaceOnUse">
            <stop stop-color="#869337"/>
            <stop offset="1" stop-color="#193B2A"/>
            </linearGradient>
            <linearGradient id="paint1_linear_506_350" x1="4.09038" y1="17.1502" x2="14.7971" y2="22.1884" gradientUnits="userSpaceOnUse">
            <stop stop-color="#869337"/>
            <stop offset="1" stop-color="#193B2A"/>
            </linearGradient>
            <linearGradient id="paint2_linear_506_350" x1="16.4664" y1="7.00798" x2="21.6087" y2="8.31219" gradientUnits="userSpaceOnUse">
            <stop stop-color="#869337"/>
            <stop offset="1" stop-color="#193B2A"/>
            </linearGradient>
            <linearGradient id="paint3_linear_506_350" x1="16.5728" y1="16.2724" x2="23.2372" y2="18.5468" gradientUnits="userSpaceOnUse">
            <stop stop-color="#869337"/>
            <stop offset="1" stop-color="#193B2A"/>
            </linearGradient>
            </defs>
            </svg>
            ) 
    },
    { 
        title: 'Total Hectares Analyzed', 
        value: 3275, 
        Icon: (<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0031 2.59961H10.858C9.71956 2.59961 8.78906 3.51916 8.78906 4.65765V5.68667C8.78906 6.82516 9.70861 7.74471 10.8471 7.74471H16.0031C17.1416 7.74471 18.0612 6.82516 18.0612 5.68667V4.65765C18.0721 3.51916 17.1416 2.59961 16.0031 2.59961Z" fill="url(#paint0_linear_506_356)"/>
            <path d="M19.1676 5.68816C19.1676 7.42873 17.7445 8.85184 16.0039 8.85184H10.8588C9.11821 8.85184 7.6951 7.42873 7.6951 5.68816C7.6951 5.07513 7.03828 4.69198 6.49093 4.9766C4.9474 5.79763 3.89648 7.42873 3.89648 9.30067V19.6018C3.89648 22.2948 6.09683 24.4951 8.7898 24.4951H18.0729C20.7658 24.4951 22.9662 22.2948 22.9662 19.6018V9.30067C22.9662 7.42873 21.9153 5.79763 20.3717 4.9766C19.8244 4.69198 19.1676 5.07513 19.1676 5.68816ZM13.8473 18.9669H9.05253C8.6037 18.9669 8.2315 18.5947 8.2315 18.1459C8.2315 17.697 8.6037 17.3248 9.05253 17.3248H13.8473C14.2961 17.3248 14.6683 17.697 14.6683 18.1459C14.6683 18.5947 14.2961 18.9669 13.8473 18.9669ZM16.7154 14.5881H9.05253C8.6037 14.5881 8.2315 14.2159 8.2315 13.7671C8.2315 13.3182 8.6037 12.946 9.05253 12.946H16.7154C17.1643 12.946 17.5365 13.3182 17.5365 13.7671C17.5365 14.2159 17.1643 14.5881 16.7154 14.5881Z" fill="url(#paint1_linear_506_356)"/>
            <defs>
            <linearGradient id="paint0_linear_506_356" x1="9.74579" y1="4.01145" x2="15.7509" y2="7.35524" gradientUnits="userSpaceOnUse">
            <stop stop-color="#869337"/>
            <stop offset="1" stop-color="#193B2A"/>
            </linearGradient>
            <linearGradient id="paint1_linear_506_356" x1="5.86413" y1="10.2631" x2="20.7046" y2="14.7212" gradientUnits="userSpaceOnUse">
            <stop stop-color="#869337"/>
            <stop offset="1" stop-color="#193B2A"/>
            </linearGradient>
            </defs>
            </svg>
          ) 
      },
  ];

  return (
       <div className='mx-16'>

        <div className="flex justify-between items-center ">
          {Carddata.map((data, index) => (
          <div className='mt-6 px-5 bg-slate-50 pr-[75px] '>
            <div className='flex justify-around items-center my-4 '>
            <div className='border w-14 h-14 p-3 rounded-full'>
              {data.Icon}
            </div>
            <p className='pl-4'>
              {data.title} <p className='text-left text-xl  font-bold'>{data.value}</p>
            </p>
            </div>
            
          </div>
          ))}
          
        </div>

        </div>
      );
      
}

